/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import axios from "axios";
import "./style.css";
import _ from "lodash";
import {
  TextField,
  Grid,
  Button,
  Select,
  MenuItem,
  Tab,
  Typography,
  CircularProgress,
  IconButton,
  Checkbox,
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Logo from "../../assets/images/logo.svg";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import ExistingTemplate from "./existingTemplate";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../api/client";
import { examList } from "../grade/filter";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { PDFDocument } from "pdf-lib";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  margin: "auto",
  padding: "20px",
  border: "1px solid #888",
  width: "90%",
  height: "90%",
  overflowY: "auto",
};

const ITEM_HEIGHTS = 48;
const ITEM_PADDINGS_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHTS * 4.5 + ITEM_PADDINGS_TOP,
    },
  },
};

function Grade() {
  const navigate = useNavigate();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [detectQuestionConfirm, setDetectQuestionConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [question, setQuestion] = useState("");
  const [max_marks, setMaxMarks] = useState("");
  const [max_words, setMaxWords] = useState("");
  const [model_solution, setModalSolution] = useState("");
  const [special_instructions, setSpecialInstruction] = useState("");
  const [file, setFile] = useState(null);
  const [openLogoutBox, setOpenLogoutBox] = useState(false);
  const [formState, setFormState] = useState({
    examName: "",
    fileType: "",
    studentName: "",
  });
  const [handwriting, setHandwriting] = useState("");
  const [saveTemplateName, setSaveTemplateName] = useState("");
  const [tabValue, setTabValue] = React.useState(0);
  const initValues = {
    studentName: "",
    examName: "",
    fileType: "",
  };
  const [formErrors, setFormErrors] = useState({});
  const [formTemplateErrors, setFormTemplateErrors] = useState({});
  const [isFinalized, setIsFinalized] = useState(false);
  const [, setQuestionsArrayState] = useState([]);
  const [, setPagesPerQuestionArrayState] = useState([]);
  const [, setEmptyStringsArrayState] = useState([]);
  const [, setMaxMarksArrayState] = useState([]);
  const [, setMaxWordsArrayState] = useState([]);
  const [, setModelSolutionArrayState] = useState([]);
  const [deletedImages, setDeletedImagesState] = useState([]);
  const [totalScore, setTotalScore] = useState();
  const [gradeEssayAddonPayload, setGradeEssayAddonPayload] = useState();
  const [essayId, setEssayId] = useState();
  const [, setDraggedElementParent] = useState("");
  const errors = {};
  const templateError = {};
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const roleType = JSON.parse(localStorage.getItem("userDetails"));
  const [filePageCount, setFilePageCount] = useState(0);
  const [saveTemplateLoading, setSaveTemplateLoading] = useState(false);
  const [nextBtnFlag, setNextBtnFlag] = useState();
  const [radioFlag, setRadioFlag] = useState(false);
  const [getPreviewCheck, setGetPreviewCheck] = useState(true);
  const [totalQues, setTotalQues] = useState("");

  const initialStudents = [{ studentName: "", fileType: "", handwriting: "" }];
  const [students, setStudents] = useState([]);
  const [showStudentInfo, setShowStudentInfo] = useState(false);
  const [examName, setExamName] = useState("");
  const [buttons, setButtons] = useState([]);
  const [checkedStates, setCheckedStates] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [confirmedData, setConfirmedData] = useState([]);
  const [showGradedInfo, setShowGradedInfo] = useState(false);
  const [gradeButtons, setGradeButtons] = useState([]);
  const [newData, setNewData] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const currentDate = moment(new Date()).toISOString();
    if (currentDate > roleType?.expiry) {
      localStorage.removeItem("userDetails");
      toast.error("session expired");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/getstudents/?teacherid=${roleType?.loginData?.id}`)
      .then((res) => {
        const respo = res?.status === 200;
        if (respo) {
          setStudentData(res?.data?.students);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("error in axios", err);
      });
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setFormErrors({});
    setFormTemplateErrors({});
    setRows([]);
    setSaveTemplateName("");
    setMaxMarks("");
    setMaxWords();
    setQuestion();
    setModalSolution("");
    setSpecialInstruction("");
  };

  const handleFileInputChange = (index, setFieldValue) => (e) => {
    const userDetails = localStorage.getItem("userDetails");

    // Clear all items in local storage
    localStorage.clear();

    // Restore the userDetails item back to local storage
    if (userDetails !== null) {
      localStorage.setItem("userDetails", userDetails);
    }
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target.result;
        const pdfDoc = await PDFDocument.load(new Uint8Array(buffer));
        const numPages = pdfDoc.getPageCount();

        setFieldValue(`students[${index}].fileType`, selectedFile);
        setFieldValue(`students[${index}].pageCount`, numPages);
      };
      reader.readAsArrayBuffer(selectedFile);
    } else {
      setFieldValue(`students[${index}].fileType`, null);
      setFieldValue(`students[${index}].pageCount`, 0);
    }
  };

  // const handleNextFunction = (values) => {
  //   if (!values.examName) {
  //     errors.examName = "* Please select exam";
  //   }
  //   if (!file) {
  //     errors.fileType = "* Please upload a file";
  //   }
  //   if (!values.studentName) {
  //     errors.studentName = "* Please select student";
  //   }
  //   if (handwriting === "") {
  //     console.log("* Please select an option");
  //     errors.handwriting = "* Please select an option";
  //   }
  //   setFormErrors(errors);
  //   if (Object.keys(errors).length === 0) {
  //     setFormState({
  //       examName: values.examName,
  //       fileType: file,
  //       studentName: values.studentName,
  //       isHandwriting: handwriting,
  //     });
  //     handleConfirmClickOpen();
  //   }
  // };

  const handleNextFunction = () => {
    handleConfirmClickOpen();
  };

  const agreeConfirmAction = () => {
    handleConfirmClose();
    console.log("oyeeeeeee", students, examName);
    handleGetPreviewWithoutTemplate(students, examName);
    setNextBtnFlag(false);
  };

  const disagreeConfirmAction = () => {
    handleConfirmClose();
    handleOpen();
  };

  const handleLinkClick = (resData, index) => {
    const newWindow = window.open("/grade/detected", "_blank");

    if (newWindow) {
      newWindow.addEventListener("load", () => {
        console.log("New window loaded. Sending postMessage...");
        console.log("heyyy", resData);
        newWindow.postMessage(
          {
            type: "myAppMessage",
            state: resData,
            index: index,
            exam: examName,
          },
          window.location.origin
        );
      });
    } else {
      console.error(
        "Failed to open new window. It may be blocked by the browser."
      );
    }
  };

  const handleGradeLinkClick = (resData) => {
    const newWindow = window.open("/essay/feedback", "_blank");

    if (newWindow) {
      newWindow.addEventListener("load", () => {
        console.log("New window loaded. Sending postMessage...");
        console.log("heyyy", resData);
        newWindow.postMessage(
          { type: "myAppMessage", state: resData },
          window.location.origin
        );
      });
    } else {
      console.error(
        "Failed to open new window. It may be blocked by the browser."
      );
    }
  };

  const handleGetPreviewWithoutTemplate = async (students, examName) => {
    if (!students || students.length === 0) {
      console.error("Students array is not defined or is empty");
      return;
    }

    console.log("Processing students:", students);

    setProgressLinearFlag(true);
    setProgressLinear(0); // Start the progress bar at 0%

    const totalPages = students.reduce(
      (acc, student) => acc + student.pageCount,
      0
    );

    // Calculate the estimated time for all pages (6 pages = 75 seconds)
    const estimatedTimePerPage = 75 / 6; // seconds per page
    const totalEstimatedTime = totalPages * estimatedTimePerPage; // total time in seconds

    const startTime = Date.now();

    const updateProgress = () => {
      const elapsedTime = (Date.now() - startTime) / 1000; // elapsed time in seconds
      const estimatedProgress = (elapsedTime / totalEstimatedTime) * 100;
      setProgressLinear(Math.min(estimatedProgress, 99));
    };

    // Set up an interval to update the progress bar every 100 milliseconds (0.1 seconds)
    const progressInterval = setInterval(updateProgress, 100);

    try {
      const results = [];
      const totalStudents = students.length;
      let processedPages = 0;

      for (let i = 0; i < totalStudents; i++) {
        const student = students[i];
        const formData = new FormData();
        formData.append("flag", 1);
        formData.append("teacher", roleType?.loginData?.id);
        formData.append("student", student.studentName);
        formData.append("exam", examName);
        formData.append("file", student.fileType);
        formData.append("isHandwriting", student.handwriting);

        try {
          const res = await axios.post(`${BASE_URL}/apiview/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          displayQuestionsAndImagesWithoutTemplate(res?.data?.data_json);
          setEssayId(res?.data?.id);
          results.push(res.data);
          console.log(`Successfully processed student: ${student.studentName}`);

          setButtons((prevButtons) => [
            ...prevButtons,
            <button
              onClick={() => handleLinkClick(res.data, i)}
              key={student.studentName}
            >
              Check
            </button>,
          ]);

          // Update the processed pages count
          processedPages += student.pageCount;
        } catch (err) {
          console.error(
            `Error processing student: ${student.studentName}`,
            err.response || err
          );
        }
      }
      setConfirmedData(results);
      console.log("All API calls completed successfully:", results);
      setStudents(students);
      setCheckedStates(new Array(students.length).fill(false));
      setShowStudentInfo(true);
    } catch (err) {
      console.error("Error in processing students:", err);
    } finally {
      // Ensure the progress bar is fully completed at the end
      clearInterval(progressInterval);
      setProgressLinear(100);
      setTimeout(() => setProgressLinearFlag(false), 1000);
    }
  };

  const handleHandWritingChange = (event) => {
    setHandwriting(parseInt(event.target.value));
  };

  const handleAddDataFunction = () => {
    if (handwriting === "") {
      errors.handwriting = "* Please select any option";
    }
    if (!question.trim()) {
      errors.question = "* Please fill the field";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const newRow = {
        question,
        max_marks,
        max_words,
        model_solution,
        special_instructions,
      };
      if (editingIndex !== null) {
        const updatedRows = [...rows];
        updatedRows[editingIndex] = newRow;
        setRows(updatedRows);
        setEditingIndex(null);
      } else {
        setRows([...rows, newRow]);
      }
      resetFields();
    }
  };

  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const editRow = (index) => {
    const row = rows[index];
    setQuestion(row.question);
    setMaxMarks(row.max_marks);
    setMaxWords(row.max_words);
    setModalSolution(row.model_solution);
    setSpecialInstruction(row.special_instructions);
    setEditingIndex(index);
    handleOpen();
  };

  const resetFields = () => {
    setQuestion("");
    setMaxMarks("");
    setMaxWords("");
    setModalSolution("");
    setSpecialInstruction("");
  };

  const handleSaveTemplate = () => {
    if (!saveTemplateName.trim()) {
      templateError.saveTemplateName = "* Please fill the field";
    }
    setFormTemplateErrors(templateError);
    setGetPreviewCheck(true);
    let roleType = JSON.parse(localStorage.getItem("userDetails"));
    if (Object.keys(templateError).length === 0) {
      let tableData = [];
      for (var i = 0; i < rows?.length; i++) {
        var row = rows[i];
        var rowData = {
          question: row?.question ? row?.question : "",
          max_marks: row?.max_marks ? row?.max_marks : "",
          max_words: row?.max_words ? row?.max_words : "",
          model_solution: row?.model_solution ? row?.model_solution : "",
          special_instructions: row?.special_instructions
            ? row?.special_instructions
            : "",
        };
        tableData.push(rowData);
      }
      let payload = {
        teacher: roleType?.loginData?.id,
        template: {
          name: saveTemplateName,
          data: JSON.stringify(tableData),
          createdAt: moment(new Date()).format("YYYY-MM-DD h:mm:ss a"),
          createdBy: roleType?.loginData?.username,
          isHandwriting: handwriting,
        },
      };
      setSaveTemplateLoading(true);
      axios
        .post(`${BASE_URL}/savetemplates/`, payload)
        .then((res) => {
          const respo = res?.status === 200;
          if (respo) {
            setSaveTemplateLoading(false);
            setGetPreviewCheck(false);
            toast.success("Template saved successfully");
          } else {
            toast.error("Template save failed");
            setSaveTemplateLoading(false);
          }
        })
        .catch((err) => {
          setSaveTemplateLoading(false);
          toast.error("Template save failed");
          console.log("error in axios", err);
        });
    }
  };

  const handleConfirmClickOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleDetectQuestionOpen = () => {
    if (getPreviewCheck) {
      toast.error("Please save the template");
    } else {
      setDetectQuestionConfirm(true);
    }
  };

  const handleGetPreview = async () => {
    setDetectQuestionConfirm(false);
    setProgressLinearFlag(true);
    setOpen(false);
    setNextBtnFlag(false);

    if (!students || students.length === 0) {
      console.error("Students array is not defined or is empty");
      return;
    }

    console.log("Processing students:", students);

    const totalPages = students.reduce(
      (acc, student) => acc + student.pageCount,
      0
    );
    const estimatedTimePerPage = 120 / 6; // seconds per page
    const totalEstimatedTime = totalPages * estimatedTimePerPage; // total time in seconds

    const startTime = Date.now();

    const updateProgress = () => {
      const elapsedTime = (Date.now() - startTime) / 1000; // elapsed time in seconds
      const estimatedProgress = (elapsedTime / totalEstimatedTime) * 100;
      setProgressLinear(Math.min(estimatedProgress, 99));
    };

    const progressInterval = setInterval(updateProgress, 100);

    try {
      const results = [];
      for (let i = 0; i < students.length; i++) {
        const student = students[i];
        let addOnPayload = {};
        addOnPayload.total_score = 0;
        addOnPayload.template_questions = rows; // Assuming rows is available in your context
        addOnPayload.is_handwritten = student.handwriting;

        const formData = new FormData();
        formData.append("flag", 1);
        formData.append("teacher", roleType?.loginData?.id);
        formData.append("student", student.studentName);
        formData.append("exam", examName);
        formData.append("file", student.fileType);
        formData.append("isHandwriting", student.handwriting);
        formData.append("addonpayload", JSON.stringify(addOnPayload));

        try {
          const res = await axios.post(`${BASE_URL}/apiview/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          console.log("API Response:", res); // Log the response object

          if (res.status === 200 && res.data) {
            results.push(res.data);

            setTotalScore(res?.data?.data_json?.total_score);
            setButtons((prevButtons) => [
              ...prevButtons,
              <button
                onClick={() => handleLinkClick(res.data, i)}
                key={student.studentName}
              >
                Check
              </button>,
            ]);
            setEssayId(res?.data?.id);
            document.querySelector(".finalizewrapper").style.display = "none";
            document.querySelector(".instructions-content").style.display =
              "none";
            initiatePopUpAction();
            toast.success("Detection questions with template successfully");
            setRadioFlag(true);
          } else {
            throw new Error("Failed to get a valid response from the server");
          }
        } catch (err) {
          toast.error("Questions detection failed");
          console.error("Error in axios", err);
          setNextBtnFlag(true);
          setRadioFlag(false);
        }
      }

      console.log("All API calls completed successfully:", results);
      setStudents(students);
      setCheckedStates(new Array(students.length).fill(false));
      setShowStudentInfo(true);
      setConfirmedData(results);
    } catch (err) {
      console.error("Error in processing students:", err);
      toast.error("Detection questions with template failed");
      setProgressLinearFlag(false);
      setNextBtnFlag(true);
      setRadioFlag(false);
    } finally {
      clearInterval(progressInterval);
      setProgressLinear(100);
      setTimeout(() => setProgressLinearFlag(false), 1000);
    }
  };

  const hanldeApplyfunction = async (e, viewTableData, students, examType) => {
    setOpen(false);
    setProgressLinearFlag(true);
    setNextBtnFlag(false);

    if (!students || students.length === 0) {
      console.error("Students array is not defined or is empty");
      return;
    }

    console.log("Processing students:", students);

    const totalPages = students.reduce(
      (acc, student) => acc + student.pageCount,
      0
    );

    const estimatedTimePerPage = 120 / 6; // seconds per page
    const totalEstimatedTime = totalPages * estimatedTimePerPage; // total time in seconds

    const startTime = Date.now();

    const updateProgress = () => {
      const elapsedTime = (Date.now() - startTime) / 1000; // elapsed time in seconds
      const estimatedProgress = (elapsedTime / totalEstimatedTime) * 100;
      setProgressLinear(Math.min(estimatedProgress, 99));
    };

    const progressInterval = setInterval(updateProgress, 100);

    try {
      const results = [];
      const totalStudents = students.length;

      for (let i = 0; i < totalStudents; i++) {
        const student = students[i];
        let ApplyTemplatePayload = {};
        ApplyTemplatePayload.total_score = 0;
        let tableData = [];
        console.log("View table data", viewTableData);
        for (var j = 0; j < viewTableData?.length; j++) {
          var row = viewTableData[j];
          var rowData = {
            question: row?.question,
            max_marks: row?.max_marks,
            max_words: row?.max_words,
            model_solution: row?.model_solution,
            special_instructions: row?.special_instructions,
          };
          tableData.push(rowData);
        }
        ApplyTemplatePayload.template_questions = [...tableData];
        ApplyTemplatePayload.is_handwritten = student.handwriting;

        const formData = new FormData();
        formData.append("flag", 1);
        formData.append("teacher", roleType?.loginData?.id);
        formData.append("student", student.studentName);
        formData.append("exam", examType);
        formData.append("file", student.fileType);
        formData.append("isHandwriting", student.handwriting);
        formData.append("addonpayload", JSON.stringify(ApplyTemplatePayload));

        try {
          const res = await axios.post(`${BASE_URL}/apiview/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          setButtons((prevButtons) => [
            ...prevButtons,
            <button
              onClick={() => handleLinkClick(res.data, i)}
              key={student.studentName}
            >
              Check
            </button>,
          ]);
        } catch (err) {
          console.error(
            `Error processing student: ${student.studentName}`,
            err.response || err
          );
        }
      }
      setConfirmedData(results);
      console.log("All API calls completed successfully:", results);
      // document.getElementById("gradeButton").style.display = "none";
      document.querySelector(".finalizewrapper").style.display = "none";
      document.querySelector(".instructions-content").style.display = "none";
      initiatePopUpAction();
      toast.success("Detection questions with template successfully");
      setRadioFlag(true);
      setStudents(students);
      setCheckedStates(new Array(students.length).fill(false));
      setShowStudentInfo(true);
    } catch (err) {
      console.error("Error in processing students:", err);
      toast.error("Detection questions with template failed");
      setProgressLinearFlag(false);
      setNextBtnFlag(true);
      setRadioFlag(false);
    } finally {
      clearInterval(progressInterval);
      setProgressLinear(100);
      setTimeout(() => setProgressLinearFlag(false), 1000);
    }
  };

  function displayQuestionsAndImagesWithoutTemplate(data) {
    const container = document.getElementById("responseContainer");
    const headingsRow = document.createElement("div");
    headingsRow.className = "row headings-row";
    headingsRow.innerHTML = `
            <div class="column question-column" style="display: flex; justify-content: center;"><strong>Question</strong></div>
            <div class="column image-column-header" style="display: flex; justify-content: center;"><strong>Pages</strong></div>
            <div class="column ModelSolution-textbox-column-header" style="display: flex; justify-content: center;"><strong>Model Solution</strong></div>
            <div class="column textbox-column-header" style="display: flex; justify-content: center;"><strong>Special Instructions</strong></div>
        `;
    container.innerHTML = "";
    container.appendChild(headingsRow);

    // console.log(data);

    data?.question_respones?.forEach((question, index) => {
      const rowDiv = document.createElement("div");
      rowDiv.className = "row data-row";
      rowDiv.setAttribute("data-index", index);

      // Question column
      const questionDiv = document.createElement("div");
      questionDiv.className = "column question-column";
      const questionInput = document.createElement("textarea");
      const questionMetaDataDiv = document.createElement("div");
      questionMetaDataDiv.className = "questionMetaDataDiv";
      const wrapElem1 = document.createElement("div");
      wrapElem1.className = "sampleSpanWrapper";
      const maxMarksElem = document.createElement("span");
      maxMarksElem.innerText = "Maximum marks per question : ";
      const maxMarksValueElem = document.createElement("span");
      maxMarksValueElem.className = "maxMarksValueElem";
      maxMarksValueElem.innerText = question.question_points
        ? question.question_points
        : 0;
      maxMarksValueElem.contentEditable = true;
      maxMarksValueElem.addEventListener("click", function () {
        maxMarksValueElem.classList.toggle("highlight");
      });
      wrapElem1.appendChild(maxMarksElem);
      wrapElem1.appendChild(maxMarksValueElem);
      questionMetaDataDiv.appendChild(wrapElem1);
      const wrapElem2 = document.createElement("div");
      wrapElem2.className = "sampleSpanWrapper";
      const maxWordsElem = document.createElement("span");
      maxWordsElem.innerText = "Maximum words per question : ";
      const maxWordsValueElem = document.createElement("span");
      maxWordsValueElem.className = "maxWordsValueElem";
      maxWordsValueElem.innerText = question.words_parsed
        ? question.words_parsed
        : 0;
      maxWordsValueElem.contentEditable = true;
      maxWordsValueElem.addEventListener("click", function () {
        maxWordsValueElem.classList.toggle("highlight");
      });
      wrapElem2.appendChild(maxWordsElem);
      wrapElem2.appendChild(maxWordsValueElem);
      questionMetaDataDiv.appendChild(wrapElem2);
      questionInput.style.resize = "none";
      questionInput.style.minHeight = "7em";
      questionInput.value = question.question;
      questionInput.className =
        "questionInput form-control customAlign customtextareaScroll";
      questionInput.numPages = question.pages.length;
      questionInput.addEventListener("input", collectQuestions);
      questionDiv.appendChild(questionInput);
      questionDiv.appendChild(questionMetaDataDiv);
      questionInput.ondragover = allowDrop;
      questionInput.ondrop = function (event) {
        drop(event, index);
      };

      // Image column
      const imageDiv = document.createElement("div");
      imageDiv.className = "column image-column";
      imageDiv.ondragover = allowDrop;
      imageDiv.ondrop = function (event) {
        drop(event, index);
      };
      question.pages.forEach((page) => {
        const wrapperDiv = document.createElement("div");
        wrapperDiv.className = "question-wrapper-div";
        const img = document.createElement("img");
        img.src = page.image_url;
        img.className = "thumbnail";
        img.style.width = "80px";
        img.style.height = "140px";
        img.draggable = true;
        img.ondragstart = drag;
        img.onclick = function () {
          openModal(this.src);
        };
        const closeIcon = document.createElement("span");
        closeIcon.className = "close-icon";
        closeIcon.innerHTML = "X";
        closeIcon.onclick = function () {
          closeImageContainer(wrapperDiv);
        };
        wrapperDiv.appendChild(img);
        wrapperDiv.appendChild(closeIcon);
        imageDiv.appendChild(wrapperDiv);
        wrapperDiv.addEventListener("mouseover", function () {
          showCloseIcon(this);
        });

        wrapperDiv.addEventListener("mouseout", function () {
          hideCloseIcon(this);
        });
      });

      // Model Solution
      const modelSolutionDiv = document.createElement("div");
      modelSolutionDiv.className =
        "column ModelSolution-textbox-column modelSolutionCustomColumnBorder";
      const modelSolutioninput = document.createElement("textarea");
      modelSolutioninput.style.resize = "none";
      modelSolutioninput.style.minHeight = "7em";
      modelSolutioninput.value =
        question.specialInstructions === undefined
          ? ""
          : question.specialInstructions;
      modelSolutioninput.className =
        "form-control customAlign textareacustomclass";
      modelSolutioninput.ondragover = allowDrop;
      modelSolutioninput.ondrop = function (event) {
        drop(event, index);
      };
      modelSolutionDiv.appendChild(modelSolutioninput);
      let modalSolutionActionDiv = document.createElement("div");
      modalSolutionActionDiv.className = "instruction-action-area";
      let modalSolutionAddrowaction = document.createElement("div");
      modalSolutionAddrowaction.className = "addrowaction row-options";
      let modalSolutionInstructionsCount = document.createElement("p");
      modalSolutionInstructionsCount.setAttribute("id", "instructionsCount");
      modalSolutionInstructionsCount.className = "instCountClass";
      modalSolutionAddrowaction.appendChild(modalSolutionInstructionsCount);
      modelSolutionDiv.appendChild(modalSolutionAddrowaction);

      // Textbox column
      const textboxDiv = document.createElement("div");
      textboxDiv.className = "column textbox-column customColumnBorder";
      const input = document.createElement("textarea");
      input.style.resize = "none";
      input.style.minHeight = "7em";
      input.value =
        question?.modelSolution === undefined ? "" : question.modelSolution;
      input.className = "form-control customAlign textareacustomclass";
      input.ondragover = allowDrop;
      input.ondrop = function (event) {
        drop(event, index);
      };
      input.addEventListener("focusout", function () {
        const lines = this.value.split("\n");
        let formattedText = "";

        lines.forEach((line, index) => {
          if (line.trim() !== "") {
            if (!/^\d+\.\s/.test(line)) {
              formattedText += index + 1 + ". " + line + "\n";
            } else {
              formattedText += line + "\n";
            }
          }
        });
        this.value = formattedText.trim();
      });
      textboxDiv.appendChild(input);
      let actionDiv = document.createElement("div");
      actionDiv.className = "instruction-action-area";
      let addrowaction = document.createElement("div");
      addrowaction.className = "addrowaction row-options";
      let instructionsCount = document.createElement("p");
      instructionsCount.setAttribute("id", "instructionsCount");
      instructionsCount.className = "instCountClass";
      let addrowbtn = document.createElement("button");
      addrowbtn.innerText = "+";
      addrowbtn.className = "btn btn-secondary custombtnspacing add-row";
      let delrowbtn = document.createElement("button");
      delrowbtn.innerText = "-";
      delrowbtn.className = "btn btn-secondary custombtnspacing delete-row";
      addrowaction.appendChild(instructionsCount);
      addrowaction.appendChild(addrowbtn);
      addrowaction.appendChild(delrowbtn);
      textboxDiv.appendChild(addrowaction);

      rowDiv.appendChild(questionDiv);
      rowDiv.appendChild(imageDiv);
      rowDiv.appendChild(modelSolutionDiv);
      rowDiv.appendChild(textboxDiv);
      container.appendChild(rowDiv);
      container.style.display = "block";
    });
    assignIndexValueForImages();
  }

  function displayQuestionsAndImages(data) {
    const container = document.getElementById("responseContainer");
    const headingsRow = document.createElement("div");
    headingsRow.className = "row headings-row";
    headingsRow.innerHTML = `
            <div class="column question-column" style="display: flex; justify-content: center;"><strong>Question</strong></div>
            <div class="column image-column-header" style="display: flex; justify-content: center;"><strong>Pages</strong></div>
            <div class="column ModelSolution-textbox-column-header" style="display: flex; justify-content: center;"><strong>Model Solution</strong></div>
            <div class="column textbox-column-header" style="display: flex; justify-content: center;"><strong>Special Instructions</strong></div>
        `;
    container.innerHTML = "";
    container.appendChild(headingsRow);

    console.log(data);

    data?.question_responses?.forEach((question, index) => {
      const rowDiv = document.createElement("div");
      rowDiv.className = "row data-row";
      rowDiv.setAttribute("data-index", index);

      // Question column
      const questionDiv = document.createElement("div");
      questionDiv.className = "column question-column";
      const questionInput = document.createElement("textarea");
      const questionMetaDataDiv = document.createElement("div");
      questionMetaDataDiv.className = "questionMetaDataDiv";
      const wrapElem1 = document.createElement("div");
      wrapElem1.className = "sampleSpanWrapper";
      const maxMarksElem = document.createElement("span");
      maxMarksElem.innerText = "Maximum marks per question : ";
      const maxMarksValueElem = document.createElement("span");
      maxMarksValueElem.className = "maxMarksValueElem";
      maxMarksValueElem.innerText = question.question_points
        ? question.question_points
        : 0;
      maxMarksValueElem.contentEditable = true;
      maxMarksValueElem.addEventListener("click", function () {
        maxMarksValueElem.classList.toggle("highlight");
      });
      wrapElem1.appendChild(maxMarksElem);
      wrapElem1.appendChild(maxMarksValueElem);
      questionMetaDataDiv.appendChild(wrapElem1);
      const wrapElem2 = document.createElement("div");
      wrapElem2.className = "sampleSpanWrapper";
      const maxWordsElem = document.createElement("span");
      maxWordsElem.innerText = "Maximum words per question : ";
      const maxWordsValueElem = document.createElement("span");
      maxWordsValueElem.className = "maxWordsValueElem";
      maxWordsValueElem.innerText = question.words_parsed
        ? question.words_parsed
        : 0;
      maxWordsValueElem.contentEditable = true;
      maxWordsValueElem.addEventListener("click", function () {
        maxWordsValueElem.classList.toggle("highlight");
      });
      wrapElem2.appendChild(maxWordsElem);
      wrapElem2.appendChild(maxWordsValueElem);
      questionMetaDataDiv.appendChild(wrapElem2);
      questionInput.style.resize = "none";
      questionInput.style.minHeight = "7em";
      questionInput.value = question.question;
      questionInput.className =
        "questionInput form-control customAlign customtextareaScroll";
      questionInput.numPages = question.pages.length;
      questionInput.addEventListener("input", collectQuestions);
      questionDiv.appendChild(questionInput);
      questionDiv.appendChild(questionMetaDataDiv);
      questionInput.ondragover = allowDrop;
      questionInput.ondrop = function (event) {
        drop(event, index);
      };

      // Image column
      const imageDiv = document.createElement("div");
      imageDiv.className = "column image-column";
      imageDiv.ondragover = allowDrop;
      imageDiv.ondrop = function (event) {
        drop(event, index);
      };
      question.pages.forEach((page) => {
        const wrapperDiv = document.createElement("div");
        wrapperDiv.className = "question-wrapper-div";
        const img = document.createElement("img");
        img.src = page.image_url;
        img.className = "thumbnail";
        img.style.width = "80px";
        img.style.height = "140px";
        img.draggable = true;
        img.ondragstart = drag;
        img.onclick = function () {
          openModal(this.src);
        };
        const closeIcon = document.createElement("span");
        closeIcon.className = "close-icon";
        closeIcon.innerHTML = "X";
        closeIcon.onclick = function () {
          closeImageContainer(wrapperDiv);
        };
        wrapperDiv.appendChild(img);
        wrapperDiv.appendChild(closeIcon);
        imageDiv.appendChild(wrapperDiv);
        wrapperDiv.addEventListener("mouseover", function () {
          showCloseIcon(this);
        });

        wrapperDiv.addEventListener("mouseout", function () {
          hideCloseIcon(this);
        });
      });

      // Model Solution
      const modelSolutionDiv = document.createElement("div");
      modelSolutionDiv.className =
        "column ModelSolution-textbox-column modelSolutionCustomColumnBorder";
      const modelSolutioninput = document.createElement("textarea");
      modelSolutioninput.style.resize = "none";
      modelSolutioninput.style.minHeight = "7em";
      modelSolutioninput.value =
        question.specialInstructions === undefined
          ? ""
          : question.specialInstructions;
      modelSolutioninput.className =
        "form-control customAlign textareacustomclass";
      modelSolutioninput.ondragover = allowDrop;
      modelSolutioninput.ondrop = function (event) {
        drop(event, index);
      };
      modelSolutionDiv.appendChild(modelSolutioninput);
      let modalSolutionActionDiv = document.createElement("div");
      modalSolutionActionDiv.className = "instruction-action-area";
      let modalSolutionAddrowaction = document.createElement("div");
      modalSolutionAddrowaction.className = "addrowaction row-options";
      let modalSolutionInstructionsCount = document.createElement("p");
      modalSolutionInstructionsCount.setAttribute("id", "instructionsCount");
      modalSolutionInstructionsCount.className = "instCountClass";
      modalSolutionAddrowaction.appendChild(modalSolutionInstructionsCount);
      modelSolutionDiv.appendChild(modalSolutionAddrowaction);

      // Textbox column
      const textboxDiv = document.createElement("div");
      textboxDiv.className = "column textbox-column customColumnBorder";
      const input = document.createElement("textarea");
      input.style.resize = "none";
      input.style.minHeight = "7em";
      input.value =
        question?.modelSolution === undefined ? "" : question.modelSolution;
      input.className = "form-control customAlign textareacustomclass";
      input.ondragover = allowDrop;
      input.ondrop = function (event) {
        drop(event, index);
      };
      input.addEventListener("focusout", function () {
        const lines = this.value.split("\n");
        let formattedText = "";

        lines.forEach((line, index) => {
          if (line.trim() !== "") {
            if (!/^\d+\.\s/.test(line)) {
              formattedText += index + 1 + ". " + line + "\n";
            } else {
              formattedText += line + "\n";
            }
          }
        });
        this.value = formattedText.trim();
      });
      textboxDiv.appendChild(input);
      let actionDiv = document.createElement("div");
      actionDiv.className = "instruction-action-area";
      let addrowaction = document.createElement("div");
      addrowaction.className = "addrowaction row-options";
      let instructionsCount = document.createElement("p");
      instructionsCount.setAttribute("id", "instructionsCount");
      instructionsCount.className = "instCountClass";
      let addrowbtn = document.createElement("button");
      addrowbtn.innerText = "+";
      addrowbtn.className = "btn btn-secondary custombtnspacing add-row";
      let delrowbtn = document.createElement("button");
      delrowbtn.innerText = "-";
      delrowbtn.className = "btn btn-secondary custombtnspacing delete-row";
      addrowaction.appendChild(instructionsCount);
      addrowaction.appendChild(addrowbtn);
      addrowaction.appendChild(delrowbtn);
      textboxDiv.appendChild(addrowaction);

      rowDiv.appendChild(questionDiv);
      rowDiv.appendChild(imageDiv);
      rowDiv.appendChild(modelSolutionDiv);
      rowDiv.appendChild(textboxDiv);
      container.appendChild(rowDiv);
      container.style.display = "block";
    });
    assignIndexValueForImages();
  }
  function assignIndexValueForImages() {
    let elems = document.getElementsByClassName("thumbnail");
    for (let iter = 0; iter < elems.length; iter++) {
      elems[iter].setAttribute("imgIndex", iter);
      elems[iter].parentElement.setAttribute("imgIndex", iter);
    }
  }

  function collectQuestions() {
    var questionTexts = [];
    var questionInputs = document.querySelectorAll(".question-column input");
    questionInputs.forEach(function (questionInput) {
      questionTexts.push(questionInput.value.trim());
    });
    return questionTexts;
  }

  function openModal(src) {
    var modal = document.getElementById("myModal");
    var modalImg = document.getElementById("modalImage");
    var span = document.getElementsByClassName("close")[0];
    modal.style.display = "block";
    modalImg.src = src;
    span.onclick = function () {
      modal.style.display = "none";
    };
    document.addEventListener("keydown", function (event) {
      if (
        event.key === "Escape" ||
        event.key === "Esc" ||
        event.keyCode === 27
      ) {
        modal.style.display = "none";
      }
    });
  }

  function closeImageContainer(container) {
    container.style.display = "none";
    container.parentNode.removeChild(container);
    const imgIndex = container.getAttribute("imgindex");
    setDeletedImagesState((prevDeletedImages) => {
      const updatedDeletedImages = [...prevDeletedImages, parseInt(imgIndex)];
      updatedDeletedImages.sort((a, b) => a - b);
      return updatedDeletedImages;
    });
  }

  function showCloseIcon(container) {
    const closeIcon = container.querySelector(".close-icon");
    closeIcon.style.visibility = "visible";
  }

  function hideCloseIcon(container) {
    const closeIcon = container.querySelector(".close-icon");
    closeIcon.style.visibility = "hidden";
  }

  function allowDrop(event) {
    event.preventDefault();
  }

  function drag(event) {
    var crt = this.cloneNode(true);
    var previewContainer = document.createElement("div");
    previewContainer.appendChild(crt);
    document.body.appendChild(previewContainer);
    event.dataTransfer.setData("text", event.target.getAttribute("imgIndex"));
    setDraggedElementParent(event.target.parentElement.parentElement);
    event.dataTransfer.setDragImage(crt, 0, 0);
    event.target.addEventListener("dragend", function () {
      previewContainer.remove();
    });
  }

  function drop(event, rowIndex) {
    event.preventDefault();
    if (event.target.tagName.toLowerCase() === "textarea") {
      return;
    }
    const draggedIndex = event.dataTransfer.getData("text");
    const draggedImage = document.querySelector(`[imgIndex='${draggedIndex}']`);
    const targetRow = event.target.closest(".row.data-row");
    const targetImageColumn = targetRow.querySelector(".image-column");
    const children = Array.from(targetImageColumn.children);
    const targetIndex = children.findIndex(
      (img) => +img.getAttribute("imgIndex") > +draggedIndex
    );

    if (children.length === 0) {
      let tempDragElemParent = draggedImage.closest(".column.image-column");
      let tempDraggedImage = draggedImage;
      if (
        parseInt(
          draggedImage.closest(".row.data-row").getAttribute("data-index")
        ) < parseInt(targetRow.getAttribute("data-index"))
      ) {
        if (
          tempDragElemParent === "" ||
          tempDragElemParent.lastChild.isEqualNode(tempDraggedImage)
        ) {
          targetImageColumn.appendChild(draggedImage);
        }
      } else {
        if (
          tempDragElemParent === "" ||
          tempDragElemParent.firstChild.isEqualNode(tempDraggedImage)
        ) {
          targetImageColumn.appendChild(draggedImage);
        }
      }
    } else {
      if (isValidRearrange(targetIndex, draggedIndex, children)) {
        if (targetIndex === -1) {
          targetImageColumn.appendChild(draggedImage);
        } else {
          targetImageColumn.insertBefore(draggedImage, children[targetIndex]);
        }
      }
    }
  }

  function isValidRearrange(targetIndex, draggedIndex, children) {
    if (targetIndex === -1) {
      const lastIndex = children.length - 1;
      const gap =
        lastIndex >= 0
          ? +draggedIndex - +children[lastIndex].getAttribute("imgIndex")
          : 0;
      return gap === 1;
    } else {
      const gap =
        +children[targetIndex].getAttribute("imgIndex") - +draggedIndex;
      return (
        gap === 1 &&
        !children.some((img) => img.getAttribute("imgIndex") === draggedIndex)
      );
    }
  }
  const handleFinalizeButton = (event) => {
    setIsFinalized(true);
    toast.success("Questions confirmed successfully");
  };

  useEffect(() => {
    setAllChecked(checkedStates.every(Boolean));
  }, [checkedStates]);

  const handleCheckboxChange = (index) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(newCheckedStates);
  };

  const handleCheckboxChangeTrue = (index) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = true;
    setCheckedStates(newCheckedStates);
  };

  window.addEventListener("message", (event) => {
    if (event.origin !== window.location.origin) {
      // Ignore messages from unexpected origins
      return;
    }

    // Only process messages with the correct type
    if (event.data && event.data.type === "CHECKBOX_CHANGE") {
      console.log("Checkbox change message received:", event.data);
      setNewData((oldData) => {
        // Check if the questionsArray is different
        if (
          oldData.length === 0 ||
          oldData[oldData.length - 1].questionsArray[0] !==
            event.data.newData.questionsArray[0]
        ) {
          return [...oldData, event.data.newData];
        }
        return oldData;
      });
      // setFlag(true);
      handleCheckboxChangeTrue(event.data.idx);
    } else {
      console.log("Ignored message:", event.data);
    }
  });

  const handleGradeButton = async (students, examName, confirmedData) => {
    if (!allChecked) {
      toast.warning("Please confirm the questions for each student");
      return;
    }

    setLoading(true);

    const handleStudentGrade = async (student, index) => {
      const formData = new FormData();
      formData.append("flag", 0);
      formData.append("teacher", roleType?.loginData?.id);
      formData.append("student", student.studentName);
      formData.append("exam", examName);
      formData.append("file", student.fileType);
      formData.append("isHandwriting", student.handwriting);

      if (!flag) {
        formData.append(
          "newquestions",
          JSON.stringify(confirmedData[index]?.questionsArray)
        );
        formData.append(
          "newpages",
          JSON.stringify(confirmedData[index]?.pagesPerQuestionArray)
        );
        formData.append(
          "newprompts",
          JSON.stringify(confirmedData[index]?.emptyStringsArray)
        );
        formData.append(
          "deletedPages",
          JSON.stringify(confirmedData[index]?.deletedImages)
        );
        formData.append(
          "maxMarks",
          JSON.stringify(confirmedData[index]?.maxMarksArray)
        );
        formData.append(
          "maxWords",
          JSON.stringify(confirmedData[index]?.maxWordsArray)
        );
        formData.append(
          "newmodalSolution",
          JSON.stringify(confirmedData[index]?.modelSolutionArray)
        );
        formData.append("essayId", -1);
      } else {
        let questionsArray = [];
        let pagesPerQuestionArray = [];
        let emptyStringsArray = [];
        let maxMarksArray = [];
        let maxWordsArray = [];
        let modelSolutionArray = [];

        confirmedData?.forEach((data) => {
          data?.data_json?.question_responses?.forEach((ques) => {
            questionsArray.push(ques?.question);
            pagesPerQuestionArray.push(ques?.pages?.length);
            maxMarksArray.push(ques?.question_points);
            maxWordsArray.push(ques?.words_parsed);
          });
        });

        formData.append("newquestions", JSON.stringify(questionsArray));
        formData.append("newpages", JSON.stringify(pagesPerQuestionArray));
        formData.append("newprompts", JSON.stringify(emptyStringsArray));
        formData.append("deletedPages", JSON.stringify(deletedImages));
        formData.append("maxMarks", JSON.stringify(maxMarksArray));
        formData.append("maxWords", JSON.stringify(maxWordsArray));
        formData.append("newmodalSolution", JSON.stringify(modelSolutionArray));
        formData.append("essayId", confirmedData[0]?.id);
      }

      try {
        const res = await axios.post(`${BASE_URL}/apiview/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Grade essay successfully");
        console.log(res);

        setGradeButtons((prevButtons) => [
          ...prevButtons,
          <button
            onClick={() => handleGradeLinkClick(res?.data)}
            key={student.studentName}
          >
            Check Feedback
          </button>,
        ]);

        return res;
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Grade essay failed");

        setRadioFlag(false);
        setNextBtnFlag(true);
        document.getElementById("gradeButton").style.display = "none";
        document.getElementById("responseContainer").style.display = "none";
        document.querySelector(".finalizewrapper").style.display = "none";
        document.querySelector(".instructions-content").style.display = "none";

        throw error;
      }
    };

    try {
      const promises = students.map((student, index) =>
        handleStudentGrade(student, index)
      );
      await Promise.all(promises);

      setShowStudentInfo(false);
      setShowGradedInfo(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const handlePreviewButton = async (student, examName, confirmedData) => {
  //   if (!allChecked) {
  //     toast.warning("Please confirm the questions for each student");
  //     return;
  //   }

  //   setLoading(true);

  //   const handleStudentGrade = async (student) => {
  //     const formData = new FormData();
  //     formData.append("flag", 0);
  //     formData.append("teacher", roleType?.loginData?.id);
  //     formData.append("student", student.studentName);
  //     formData.append("exam", examName);
  //     formData.append("file", student.fileType);
  //     formData.append("isHandwriting", student.handwriting);

  //     if (flag) {
  //       formData.append(
  //         "newquestions",
  //         JSON.stringify(confirmedData[0]?.questionsArray)
  //       );
  //       formData.append(
  //         "newpages",
  //         JSON.stringify(confirmedData[0]?.pagesPerQuestionArray)
  //       );
  //       formData.append(
  //         "newprompts",
  //         JSON.stringify(confirmedData[0]?.emptyStringsArray)
  //       );
  //       formData.append(
  //         "deletedPages",
  //         JSON.stringify(confirmedData[0]?.deletedImages)
  //       );
  //       formData.append(
  //         "maxMarks",
  //         JSON.stringify(confirmedData[0]?.maxMarksArray)
  //       );
  //       formData.append(
  //         "maxWords",
  //         JSON.stringify(confirmedData[0]?.maxWordsArray)
  //       );
  //       formData.append(
  //         "newmodalSolution",
  //         JSON.stringify(confirmedData[0]?.modelSolutionArray)
  //       );
  //       formData.append("essayId", -1);
  //     } else {
  //       let questionsArray = [];
  //       let pagesPerQuestionArray = [];
  //       let emptyStringsArray = [];
  //       let maxMarksArray = [];
  //       let maxWordsArray = [];
  //       let modelSolutionArray = [];

  //       confirmedData?.forEach((data) => {
  //         data?.data_json?.question_responses?.forEach((ques) => {
  //           questionsArray.push(ques?.question);
  //           pagesPerQuestionArray.push(ques?.pages?.length);
  //           maxMarksArray.push(ques?.question_points);
  //           maxWordsArray.push(ques?.words_parsed);
  //         });
  //       });

  //       formData.append("newquestions", JSON.stringify(questionsArray));
  //       formData.append("newpages", JSON.stringify(pagesPerQuestionArray));
  //       formData.append("newprompts", JSON.stringify(emptyStringsArray));
  //       formData.append("deletedPages", JSON.stringify(deletedImages));
  //       formData.append("maxMarks", JSON.stringify(maxMarksArray));
  //       formData.append("maxWords", JSON.stringify(maxWordsArray));
  //       formData.append("newmodalSolution", JSON.stringify(modelSolutionArray));
  //       formData.append("essayId", confirmedData[0]?.id);
  //     }

  //     try {
  //       const res = await axios.post(`${BASE_URL}/apiview/`, formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });

  //       toast.success("Grade essay successfully");
  //       console.log(res);

  //       setGradeButtons((prevButtons) => [
  //         ...prevButtons,
  //         <button
  //           onClick={() => handleGradeLinkClick(res?.data)}
  //           key={student.studentName}
  //         >
  //           Check Feedback
  //         </button>,
  //       ]);

  //       return res;
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       toast.error("Grade essay failed");

  //       setRadioFlag(false);
  //       setNextBtnFlag(true);
  //       document.getElementById("gradeButton").style.display = "none";
  //       document.getElementById("responseContainer").style.display = "none";
  //       document.querySelector(".finalizewrapper").style.display = "none";
  //       document.querySelector(".instructions-content").style.display = "none";

  //       throw error;
  //     }
  //   };

  //   try {
  //     await handleStudentGrade(student);
  //     setShowStudentInfo(false);
  //     setShowGradedInfo(true);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  function initiatePopUpAction() {
    $(document).off("click", ".add-row");
    $(document).off("click", ".delete-row");
    $(document).on("click", ".add-row", function () {
      var newRow = $(this).closest(".row").clone();
      var addClickedRow = $(this).closest(".row");
      let newIndex = parseInt(addClickedRow[0].dataset.index) + 1;
      newRow.find("input[type=text]").val("");
      newRow.children()[1].innerHTML = "";
      newRow.children().addClass("new-rowadded-bg");
      newRow.find("textarea")[0].value = "";
      newRow.find("textarea")[1].value = "";
      newRow.find("textarea")[2].value = "";
      newRow.find(".maxMarksValueElem")[0].innerText = 0;
      newRow.find(".maxWordsValueElem")[0].innerText = 0;
      newRow.attr("data-index", newIndex);
      newRow.find(".image-column").on("dragover", function (event) {
        event.preventDefault();
      });
      newRow.find(".image-column").on("drop", function (event) {
        var originalEvent = event.originalEvent;
        drop(originalEvent, newIndex);
      });

      newRow.find(".thumbnail").off("dragstart");
      newRow.find(".thumbnail").attr("draggable", true);
      newRow.find(".thumbnail").on("dragstart", drag);

      newRow.find("textarea")[2].addEventListener("focusout", function () {
        const lines = this.value.split("\n");
        let formattedText = "";

        lines.forEach((line, index) => {
          if (line.trim() !== "") {
            if (!/^\d+\.\s/.test(line)) {
              formattedText += index + 1 + ". " + line + "\n";
            } else {
              formattedText += line + "\n";
            }
          }
        });
        this.value = formattedText.trim();
      });

      $(this).closest(".row").after(newRow);
      reassignIndexValues();
    });

    $(document).on("click", ".delete-row", function () {
      if ($(".row").length > 1) {
        let closestRow = $(this).closest(".row");
        let isEmptyDiv = checkEmptyDivExists(closestRow);
        if (isEmptyDiv) {
          $(this).closest(".row").remove();
          reassignIndexValues();
        } else {
          toast.error("Cannot delete row that contains existing values");
        }
      } else {
        toast.error("Cannot delete the only row.");
      }
    });
  }

  function reassignIndexValues() {
    const elements = document.querySelectorAll(".row.data-row");
    setTotalQues(elements?.length);
    elements.forEach((element, index) => {
      element.dataset.index = index;
    });
  }

  function checkEmptyDivExists(elem) {
    return (
      elem.find(".column.image-column").find("img").length === 0 &&
      elem.find(".question-column textarea").val().trim() === "" &&
      elem.find(".textbox-column textarea").val().trim() === "" &&
      elem.find(".ModelSolution-textbox-column textarea").val().trim() === ""
    );
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleLogout = () => {
    setOpenLogoutBox(false);
    toast.success("Logged out successfully");
    setTimeout(() => {
      localStorage.removeItem("userDetails");
      navigate("/");
    }, 500);
  };

  const handleLogoutConfirmClose = () => {
    setOpenLogoutBox(false);
  };

  const handleLogoutConfirmOpen = () => {
    setOpenLogoutBox(true);
  };

  const handleGoToEssay = () => {
    navigate("/essays");
  };

  const handleGoToQuick = () => {
    navigate("/quick");
  };

  const [progressLinear, setProgressLinear] = useState(0);
  const [progressLinearFlag, setProgressLinearFlag] = useState(false);

  return (
    <>
      {progressLinearFlag && (
        <div className="linear-progress-overlay">
          <div className="loader-linear-wrapper">
            <LinearProgressWithLabel value={progressLinear} />
          </div>
        </div>
      )}

      {loading && (
        <div className="grade-page-loader">
          <CircularProgress />
        </div>
      )}
      <Dialog
        open={openLogoutBox}
        onClose={handleLogoutConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="agree-disagree-btn-div">
          <Button
            variant="contained"
            onClick={handleLogoutConfirmClose}
            className="disagree-btn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleLogout}
            style={{ textTransform: "capitalize" }}
            autoFocus
          >
            logout
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to proceed without template?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="agree-disagree-btn-div">
          <Button
            variant="contained"
            className="disagree-btn"
            onClick={disagreeConfirmAction}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            style={{ textTransform: "capitalize" }}
            onClick={agreeConfirmAction}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={detectQuestionConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Have you added all the questions?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="agree-disagree-btn-div">
          <Button
            variant="contained"
            className="disagree-btn"
            onClick={() => setDetectQuestionConfirm(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            style={{ textTransform: "capitalize" }}
            onClick={handleGetPreview}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <div className="loader-wrapper" style={{ display: "none" }}>
        <CircularProgress />
      </div>
      <Grid container>
        <Grid item md={12} lg={12} sm={12}>
          <div className="logo-back-parent-div">
            <div>
              <img src={Logo} alt="Logo" />
            </div>
            <div className="logout-go-to-essay-partent-div">
              {/* <div
                style={{ margin: "0px 16px 0px 0px" }}
                className="view-profile-tag"
                onClick={handleGoToQuick}
              >
                Quick mode
              </div> */}
              <div
                style={{ margin: "0px 16px 0px 0px" }}
                className="view-profile-tag"
                onClick={handleGoToEssay}
              >
                Go to Dashboard
              </div>
              <Button
                variant="outlined"
                className="grade-page-logout-btn"
                onClick={handleLogoutConfirmOpen}
              >
                {" "}
                <LogoutIcon style={{ fontSize: "20px" }} /> Logout
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item md={12} lg={12} sm={12}>
          <div className="parent-div">
            <div className="custom-box-style">
              <h5 className="grade-newEssay-lable">Grade new Answers</h5>
              <Formik
                initialValues={{ students: initialStudents, examName: "" }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {
                  const { students, examName } = values;
                  // handleGetPreviewWithoutTemplate(students, examName);
                  setStudents(students);
                  setExamName(examName);
                  handleNextFunction();
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="grade-field-warps">
                      <Select
                        id="examName"
                        name="examName"
                        placeholder="Enter exam"
                        className="login-text-fields"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setRadioFlag(false);
                        }}
                        sx={{
                          "& .MuiSelect-select .notranslate::after":
                            "Select a filter"
                              ? { content: `"Select exam"`, opacity: 1 }
                              : {},
                        }}
                        value={values.examName}
                      >
                        <MenuItem disabled value="">
                          <span>Select exam</span>
                        </MenuItem>
                        {examList.map((data, index) => (
                          <MenuItem key={index} value={data?.value}>
                            {data?.lable}
                          </MenuItem>
                        ))}
                      </Select>
                      <div className="errer-message">{formErrors.examName}</div>
                    </div>
                    <FieldArray
                      name="students"
                      render={(arrayHelpers) => (
                        <>
                          {values.students.map((student, index) => (
                            <Paper
                              key={index}
                              elevation={3}
                              style={{ margin: "10px 0", padding: "20px" }}
                            >
                              <div className="student-row">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <ClearIcon style={{ color: "red" }} />
                                  </IconButton>
                                </div>
                                <div className="grade-field-warps">
                                  <Select
                                    id={`students[${index}].studentName`}
                                    name={`students[${index}].studentName`}
                                    placeholder="Enter student"
                                    className="login-text-fields"
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                      handleChange(event);
                                      const selectedStudent = studentData.find(
                                        (student) =>
                                          student.id === event.target.value
                                      );
                                      setFieldValue(
                                        `students[${index}].firstName`,
                                        selectedStudent.first_name
                                      );
                                      setFieldValue(
                                        `students[${index}].lastName`,
                                        selectedStudent.last_name
                                      );
                                    }}
                                    value={student.studentName}
                                    MenuProps={MenuProps}
                                    sx={{
                                      "& .MuiSelect-select .notranslate::after":
                                        "Select a filter"
                                          ? {
                                              content: `"Select student"`,
                                              opacity: 1,
                                            }
                                          : {},
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <span>Select student</span>
                                    </MenuItem>
                                    {studentData &&
                                      studentData.length > 0 &&
                                      studentData.map((data, idx) => (
                                        <MenuItem key={idx} value={data.id}>
                                          {data.first_name} {data.last_name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  <div className="errer-message">
                                    {formErrors.studentName}
                                  </div>
                                </div>
                                <div className="grade-field-warps">
                                  <TextField
                                    type="file"
                                    className="login-text-fields"
                                    id={`students[${index}].fileType`}
                                    name={`students[${index}].fileType`}
                                    placeholder="Select file"
                                    onBlur={handleBlur}
                                    onChange={handleFileInputChange(
                                      index,
                                      setFieldValue
                                    )}
                                    inputProps={{ accept: "application/pdf" }}
                                  />
                                  <div className="errer-message">
                                    {formErrors.fileType}
                                  </div>
                                </div>
                                <div>
                                  <FormControl className="is-handwriting-formOne">
                                    <FormLabel
                                      style={{
                                        marginRight: "10px",
                                        color: "black",
                                      }}
                                    >
                                      Are the questions handwritten?
                                    </FormLabel>
                                    <RadioGroup
                                      aria-label="handwriting"
                                      name={`students[${index}].handwriting`}
                                      value={student.handwriting}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <FormControlLabel
                                        value={1}
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value={0}
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div className="errer-message">
                                  {formErrors.handwriting}
                                </div>
                              </div>
                            </Paper>
                          ))}
                          <Button
                            type="button"
                            variant="contained"
                            onClick={() =>
                              arrayHelpers.push({
                                studentName: "",
                                fileType: "",
                                handwriting: "",
                              })
                            }
                            style={{ marginTop: "20px" }}
                            disabled={
                              (nextBtnFlag === true ||
                                nextBtnFlag === undefined) === false
                            }
                          >
                            Add Student
                          </Button>
                        </>
                      )}
                    />
                    <div className="grade-next-button-warp">
                      {nextBtnFlag === true || nextBtnFlag === undefined ? (
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ textTransform: "capitalize" }}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          className="login-button"
                          onClick={() =>
                            handleGradeButton(
                              students,
                              examName,
                              !flag ? newData : confirmedData
                            )
                          }
                          id="gradeButton"
                          style={{ marginTop: 24 }}
                        >
                          Grade answers
                        </Button>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
        <div className="row-seperator"></div>
        <div
          className="show-content-total-que-warp instructions-content"
          style={{ display: "none" }}
        >
          <div>
            <h5 className="edit-questions-h5">
              Edit detected questions and custom instructions as required
            </h5>
          </div>
          <div className="ques-count-warp">
            <h3 className="remove-margin-h">
              Total questions :&nbsp;&nbsp;
              <span>
                {totalQues === "" ? $(".row.data-row").length : totalQues}
              </span>
            </h3>
            <h3 className="remove-margin-h">
              Total New questions :&nbsp;&nbsp;
              <span>{$(".column.question-column.new-rowadded-bg").length}</span>
            </h3>
          </div>
        </div>
        {showStudentInfo && (
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Detected Questions and Answers</TableCell>
                  <TableCell>Confirm</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.map((student, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {student.firstName + " " + student.lastName}
                    </TableCell>
                    <TableCell className="detected-page">
                      {buttons[index]}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={checkedStates[index]}
                        onChange={() => {
                          setFlag(true);
                          handleCheckboxChange(index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {showGradedInfo && (
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Detected Questions and Answers</TableCell>
                  <TableCell>Feedback</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.map((student, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {student.firstName + " " + student.lastName}
                    </TableCell>
                    <TableCell className="detected-page">
                      {buttons[index]}
                    </TableCell>
                    <TableCell className="detected-page">
                      {gradeButtons[index]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <div id="responseContainer" style={{ display: "none" }}>
          <div className="row">
            <div className="column question-column">
              <strong>Question</strong>
            </div>
            <div className="column image-column">
              <strong>Pages</strong>
            </div>
            <div className="column textbox-column">
              <strong>Add Instructions</strong>
            </div>
          </div>
        </div>
        <div className="edit-questions-partent-div-warps">
          <div
            className="edit-questions-div-warps finalizewrapper"
            style={{ display: "none" }}
          >
            <Button
              onClick={handleFinalizeButton}
              id="finalizeButton"
              variant="contained"
              className="finalize-btn"
            >
              Confirm Questions
            </Button>
          </div>
        </div>
        <div id="myModal" className="modal-paper">
          <div className="modal-content">
            <span className="close">&times;</span>
            <img
              id="modalImage"
              src=""
              alt="modal-img"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="New Template" {...a11yProps(0)} />
                <Tab label="Existing Template" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <div id="tab1" className="tab-content">
                <form>
                  <div className="is-handwriting-div">
                    <FormControl className="is-handwriting-formcontrol">
                      <FormLabel
                        style={{ marginRight: "10px", color: "black" }}
                      >
                        Question is handwritten
                      </FormLabel>
                      <RadioGroup
                        aria-label="handwriting"
                        name="handwriting"
                        value={handwriting}
                        onChange={handleHandWritingChange}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="errer-message-handwriting">
                    {formErrors.handwriting}
                  </div>
                  <Grid container>
                    <Grid item md={2} lg={2} sm={4}>
                      <div>
                        <p>Question:</p>
                      </div>
                    </Grid>
                    <Grid item md={10} lg={10} sm={8}>
                      <div className="grade-textArea-first-warps">
                        <TextField
                          type="text"
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          className="login-text-fields"
                          variant="outlined"
                          fullWidth
                          name="question"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                        />
                        <div className="errer-message">
                          {formErrors.question}
                        </div>
                      </div>
                      <Grid container spacing={2}>
                        <Grid item md={6} lg={6} sm={12}>
                          <div className="grade-textArea-first-warps">
                            <div>Maximum Marks:</div>
                            <TextField
                              className="login-text-fields"
                              variant="outlined"
                              fullWidth
                              type="number"
                              value={max_marks}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue >= 0) {
                                  setMaxMarks(newValue);
                                } else {
                                  setMaxMarks("");
                                }
                              }}
                              id="maxmarksinput"
                            />
                          </div>
                        </Grid>
                        <Grid item md={6} lg={6} sm={12}>
                          <div className="grade-textArea-first-warps">
                            <div>Maximum Words:</div>
                            <TextField
                              className="login-text-fields"
                              variant="outlined"
                              fullWidth
                              type="number"
                              value={max_words}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue >= 0) {
                                  setMaxWords(newValue);
                                } else {
                                  setMaxWords("");
                                }
                              }}
                              id="maxwordsinput"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={2} lg={2} sm={4}>
                      <div>
                        <p>Model Solution:</p>
                      </div>
                    </Grid>
                    <Grid item md={10} lg={10} sm={8}>
                      <div className="grade-textArea-warps">
                        <TextField
                          type="text"
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          className="login-text-fields"
                          variant="outlined"
                          fullWidth
                          value={model_solution}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (_.isEmpty(newValue.trim())) {
                              setModalSolution("");
                            } else {
                              setModalSolution(newValue);
                            }
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={2} lg={2} sm={4}>
                      <div>
                        <p>Special Instructions:</p>
                      </div>
                    </Grid>
                    <Grid item md={10} lg={10} sm={8}>
                      <div className="grade-textArea-warps">
                        <TextField
                          type="text"
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          className="login-text-fields"
                          fullWidth
                          value={special_instructions}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (_.isEmpty(newValue.trim())) {
                              setSpecialInstruction("");
                            } else {
                              setSpecialInstruction(newValue);
                            }
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="grade-next-button-warp">
                    <Button
                      onClick={handleAddDataFunction}
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                    >
                      {editingIndex !== null ? (
                        "Update"
                      ) : (
                        <>
                          Add <AddIcon style={{ fontSize: "14px" }} />
                        </>
                      )}
                    </Button>
                  </div>
                </form>
              </div>
              {rows && rows.length > 0 && (
                <div className="tab-content">
                  <TableContainer component={Paper} className="table-warps">
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="new-template-table-head-cell1">
                            QUESTIONS
                          </TableCell>
                          <TableCell className="new-template-table-head-cell2">
                            MAX MARKS
                          </TableCell>
                          <TableCell className="new-template-table-head-cell2">
                            MAX WORDS
                          </TableCell>
                          <TableCell className="new-template-table-head-cell1">
                            MODEL SOLUTION
                          </TableCell>
                          <TableCell className="new-template-table-head-cell1">
                            SPECIAL INSTRUCION
                          </TableCell>
                          <TableCell className="new-template-table-head-cell3">
                            ACTIONS
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.question}</TableCell>
                            <TableCell>{row.max_marks}</TableCell>
                            <TableCell>{row.max_words}</TableCell>
                            <TableCell>{row.model_solution}</TableCell>
                            <TableCell>{row.special_instructions}</TableCell>
                            <TableCell>
                              <div className="action-btn">
                                <div style={{ margin: "0px 5px 0px 0px" }}>
                                  <EditIcon onClick={() => editRow(index)} />
                                </div>
                                <div style={{ margin: "0px 0px 0px 5px" }}>
                                  <DeleteIcon
                                    onClick={() => deleteRow(index)}
                                  />
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", width: "44%" }}>
                      <div className="saveTemplateNameWrapper">
                        <div className="namesubsectionone">
                          <TextField
                            label="Template Name"
                            variant="outlined"
                            value={saveTemplateName}
                            type="text"
                            style={{ width: "66%" }}
                            id="saveTemplateName"
                            name="saveTemplateName"
                            placeholder=""
                            onChange={(e) =>
                              setSaveTemplateName(e.target.value)
                            }
                          />
                        </div>
                        <div className="errer-message">
                          {formTemplateErrors.saveTemplateName}
                        </div>
                        <div className="save-template-btn">
                          <Button
                            onClick={handleSaveTemplate}
                            style={{ textTransform: "capitalize" }}
                            variant="contained"
                            id="saveTEmplateForSelect"
                          >
                            {saveTemplateLoading ? (
                              <CircularProgress
                                size={24}
                                sx={{ color: "#fff" }}
                              />
                            ) : (
                              "Save Template"
                            )}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* <div className="get-preview-btn">
                      <Button
                        variant="contained"
                        // onClick={(e, viewTableData) =>
                        //   hanldeApplyfunction(
                        //     e,
                        //     viewTableData,
                        //     students,
                        //     examName
                        //   )
                        // }
                        onClick={() => handleGetPreview(students, examName)}
                        style={{ textTransform: "capitalize" }}
                        id="sendDataToApi"
                      >
                        Detect Questions
                      </Button>
                    </div> */}
                    <div className="get-preview-btn">
                      <Button
                        variant="contained"
                        onClick={() => handleDetectQuestionOpen()}
                        style={{ textTransform: "capitalize" }}
                        id="sendDataToApi"
                      >
                        Detect Questions
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ExistingTemplate
                hanldeApplyfunction={(e, viewTableData) =>
                  hanldeApplyfunction(e, viewTableData, students, examName)
                }
              />
            </TabPanel>
          </Box>
        </Modal>
      </Grid>
    </>
  );
}

export default Grade;
