import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Button,
  Tab,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FeedbackData from "../login/feedback.json";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function GradedEssay() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  console.log("state", state, data);
  const [tabValue, setTabValue] = React.useState(0);
  const [quePageTab, setQuePageTab] = useState(0);
  const [overAllTab, setOverAllTab] = useState(0);
  const [lowPriorityState, setLowPriorityState] = useState(true);
  const [mediumPriorityState, setMediumPriorityState] = useState(true);
  const [topPriorityState, setTopPriorityState] = useState(true);
  const [enableHighlightingState, setEnableHighlightingState] = useState(false);
  const [feedBackState, setFeedBackState] = useState(data);
  const [essayID, setEssayID] = useState(FeedbackData?.essay_id);
  const [feedBackQuestionModal, setFeedBackQuestionModal] = useState(false);
  const [feedBackQuestionImage, setFeedBackQuestionImage] = useState("");
  const inputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [cardErrorMessages, setCardErrorMessages] = useState([]);

  useEffect(() => {
    mediumPriorityFunction();
    lowPriorityFunction();
    topPriorityFunction();
    handleHighlights();
  }, [
    mediumPriorityState,
    enableHighlightingState,
    lowPriorityState,
    topPriorityState,
    tabValue,
  ]);

  const handleTabChange = (event, newValue) => {
    if (!errorMessage && !cardErrorMessages.some((message) => message)) {
      setTabValue(newValue);
      setQuePageTab(0);
      setOverAllTab(newValue);
    }
  };
  console.log("FeedbackData", feedBackState);

  const handleNavigate = () => {
    navigate("/grade");
  };
  // // Function to update the question text
  // contentEditable="true" onBlur={(e) => updateQuestion(questionIndex, e.target.innerText)}
  // function updateQuestion(questionIndex, newText) {
  //     if (newText !== undefined) {
  //         const updatedFeedbackData = { ...feedBackState };
  //         updatedFeedbackData.question_responses[questionIndex].question = newText;
  //         setFeedBackState(updatedFeedbackData);
  //     }
  // }

  // Function to update overall feedback
  function updateOverallFeedback(questionIndex, newText) {
    if (newText !== undefined) {
      if (newText.trim() === "") {
        // setErrorMessage('Please fill the overall feedback');
        toast.error("the overall feedback will be not empty");
        inputRef?.current?.focus();
      } else {
        setErrorMessage("");
        let arrayOfStrings = newText.split("\n");
        const updatedFeedbackData = { ...feedBackState };
        updatedFeedbackData.question_responses[questionIndex].overall_feedback =
          [...arrayOfStrings];
        setFeedBackState(updatedFeedbackData);
      }
    }
  }

  // Function to update feedback cards
  function updateFeedback(questionIndex, pageIndex, pageDataIndex, newText) {
    const updatedErrorMessages = [...cardErrorMessages];
    if (newText !== undefined) {
      if (newText.trim() === "") {
        console.error("Error: Text cannot be empty.");
        updatedErrorMessages[pageDataIndex] = "Please fill the cards";
        // toast.error("Please fill the cards")
        setCardErrorMessages(updatedErrorMessages);
      } else {
        // setCardErrorMessage("")
        updatedErrorMessages[pageDataIndex] = "";
        setCardErrorMessages(updatedErrorMessages);
        const updatedFeedBackState = [...feedBackState?.question_responses];
        updatedFeedBackState[questionIndex].pages[pageIndex].feedback[
          pageDataIndex
        ].text = newText;
        setFeedBackState({
          ...feedBackState,
          question_responses: updatedFeedBackState,
        });
      }
    }
  }

  function getPriorityBannerClass(priorityScore) {
    if (priorityScore === 3) {
      return "high-priority-banner";
    } else if (priorityScore === 2) {
      return "medium-priority-banner";
    } else {
      return "low-priority-banner";
    }
  }

  const openModal = (src) => {
    setFeedBackQuestionModal(true);
    setFeedBackQuestionImage(src);
  };

  function handleCloseModal() {
    setFeedBackQuestionModal(false);
    setFeedBackQuestionImage("");
  }

  const showPage = (questionIndex, selectedPageIndex) => {
    setOverAllTab(questionIndex);
    setQuePageTab(selectedPageIndex);
  };

  const saveFeedbackData = () => {
    let payLoad = {
      regenjson: feedBackState,
      essayId: essayID,
    };
    console.log(
      cardErrorMessages.length === 0 ? "okay" : "notokay",
      "cardErrorMessages",
      cardErrorMessages,
      cardErrorMessages.some((message) => message)
    );
    if (cardErrorMessages.some((message) => message)) {
      toast.error("Please fill the cards");
    }
    if (!errorMessage && !cardErrorMessages.some((message) => message)) {
      toast.success("Feedback saved successfully");
      console.log("save-feed-back-payLoad", payLoad);
    }
  };

  function showPriority(attr, flag) {
    let elements = document.getElementsByClassName(flag);
    const elementsArray = Array.from(elements);
    elementsArray.forEach((elem) => {
      elem.classList.remove("hidePriorityClass");
      elem.classList.add("showPriorityClass");
    });
  }

  function hidePriority(attr, flag) {
    let elements = document.getElementsByClassName(flag);
    const elementsArray = Array.from(elements);
    elementsArray.forEach((elem) => {
      elem.classList.remove("showPriorityClass");
      elem.classList.add("hidePriorityClass");
    });
  }

  const topPriorityFunction = (e) => {
    if (topPriorityState) {
      showPriority("card-banner", "high-priority-banner");
      // setTopPriorityState(e.target.checked)
    } else {
      hidePriority("card-banner", "high-priority-banner");
      // setTopPriorityState(e.target.checked)
    }
  };

  function mediumPriorityFunction(e) {
    const flag = "medium-priority-banner";
    if (mediumPriorityState) {
      showPriority("card-banner", flag);
      // setMediumPriorityState(e?.target?.checked)
    } else {
      hidePriority("card-banner", flag);
      // setMediumPriorityState(e?.target?.checked)
    }
  }

  function lowPriorityFunction(e) {
    if (lowPriorityState) {
      showPriority("card-banner", "low-priority-banner");
      // setLowPriorityState(e.target.checked)
    } else {
      hidePriority("card-banner", "low-priority-banner");
      // setLowPriorityState(e.target.checked)
    }
  }

  function removeEventListenersFromFeedbackCards() {
    const feedbackCards = document.querySelectorAll(".feedback-card");
    feedbackCards.forEach((card) => {
      card.removeEventListener("mouseover", handleMouseOver);
    });
  }

  function addEventListenersToFeedbackCards() {
    const feedbackCards = document.querySelectorAll(".feedback-card");
    feedbackCards.forEach((card) => {
      card.addEventListener("mouseover", handleMouseOver);
    });
    feedbackCards.forEach((card) => {
      card.addEventListener("mouseout", handleMouseOut);
    });
  }

  function handleMouseOver(event) {
    let card;
    if (event.target.getAttribute("class") === "feedbackprioritycontent") {
      card = event.target.parentElement;
      console.log("card-if", card, event.target.parentElement);
    } else {
      card = event.target;
      console.log("card-else", event.target, card);
    }

    // Check if the toggle button is checked and the card is present
    let dummy = document.getElementById("highlightToggle");
    if (dummy?.checked) {
      // Get the required values from attributes
      const index = Number(card.getAttribute("data-index"));
      const pageIndex = Number(card.getAttribute("data-pageIndex"));
      const feedbackBox = JSON.parse(card.getAttribute("data-feedbackBox"));

      if (feedbackBox !== null) {
        highlightEllipse(index, pageIndex, feedbackBox);
      }
      // Call the highlightEllipse function
    }
  }

  function handleMouseOut(event) {
    const card = event.target;
    const index = Number(card.getAttribute("data-index"));
    const pageIndex = Number(card.getAttribute("data-pageIndex"));
    clearEllipse(index, pageIndex);
  }

  function clearEllipse(questionIndex, pageIndex) {
    let overlayIds = "overlay-" + questionIndex + "-" + pageIndex;
    let overlays = document.getElementById(overlayIds);
    console.log(
      "pageIndex",
      pageIndex,
      "questionIndex",
      questionIndex,
      "overlays",
      overlays
    );
    if (overlays !== null) {
      overlays.style.display = "none";
    }
  }

  const handleHighlights = (e) => {
    if (enableHighlightingState) {
      // setEnableHighlightingState(e.target.checked);
      addEventListenersToFeedbackCards();
    } else {
      // setEnableHighlightingState(e.target.checked);
      removeEventListenersFromFeedbackCards();
    }
  };

  const highlightEllipse = (questionIndex, pageIndex, boxCoords) => {
    let overlayId = "overlay-" + questionIndex + "-" + pageIndex;
    let overlay = document.getElementById(overlayId);

    let boxes = [];

    // Calculate the center
    let xCenter = (boxCoords[0] + boxCoords[2]) / 2;
    let yCenter = (boxCoords[1] + boxCoords[3]) / 2;

    // Calculate radii
    // let xRadius = Math.abs(boxCoords[2] - boxCoords[0]) / 6;
    // let yRadius = Math.abs(boxCoords[3] - boxCoords[1]) / 2;
    overlay.style.setProperty("--overlay-y", `${yCenter}px`);
    overlay.style.setProperty("--overlay-x", `${xCenter}px`);

    // yCenter = 200;

    for (let i = 0; i < boxCoords.length; i += 4) {
      let x1 = boxCoords[i];
      let y1 = boxCoords[i + 1];
      let x2 = boxCoords[i + 2];
      let y2 = boxCoords[i + 3];

      // Calculate the center
      let xCenter = (x1 + x2) / 2;
      let yCenter = (y1 + y2) / 2;

      // Calculate radius (assuming yRadius is the desired radius)
      let yRadius = Math.abs(y2 - y1) / 2;

      // Store the center and radius
      boxes.push({ xCenter, yCenter, yRadius });
    }

    // boxes = [
    //    { xCenter: 150, yCenter: 100, yRadius: 40 }, // Box near the top of the image
    //    { xCenter: 300, yCenter: 250, yRadius: 50 }, // Box a bit lower
    //    { xCenter: 450, yCenter: 400, yRadius: 60 }, // Box further down
    //    { xCenter: 200, yCenter: 550, yRadius: 30 }  // Box near the bottom of the image
    //];

    boxes.sort((a, b) => a.yCenter - b.yCenter);

    let allBoxesAreZero = boxes.every(
      (box) => box.yCenter === 0 && box.yRadius === 0
    );

    let gradientStops = [];

    if (allBoxesAreZero) {
      overlay.style.background = "rgba(0, 0, 0, 0.0)";
    } else {
      gradientStops.push(`rgba(0, 0, 0, 0.2) 0%`);
      boxes.forEach((box) => {
        gradientStops.push(`rgba(0, 0, 0, 0.2) ${box.yCenter - box.yRadius}px`);
        gradientStops.push(
          `rgba(0, 0, 0, 0.1) ${box.yCenter - 0.5 * box.yRadius}px`
        );
        gradientStops.push(
          `rgba(0, 0, 0, 0.0) ${box.yCenter - 0.3 * box.yRadius}px`
        );
        gradientStops.push(`rgba(0, 0, 0, 0.0) ${box.yCenter}px`);
        gradientStops.push(
          `rgba(0, 0, 0, 0.0) ${box.yCenter + 0.3 * box.yRadius}px`
        );
        gradientStops.push(
          `rgba(0, 0, 0, 0.1) ${box.yCenter + 0.5 * box.yRadius}px`
        );
        gradientStops.push(`rgba(0, 0, 0, 0.2) ${box.yCenter + box.yRadius}px`);
      });

      // Final gradient stop at the end
      gradientStops.push(`rgba(0, 0, 0, 0.1) 100%`);

      // Set the background gradient
      overlay.style.background = `
        linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.0) 20%, rgba(0, 0, 0, 0.0) 80%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(to bottom, ${gradientStops.join(", ")})
    `;
    }

    // Show the overlay
    overlay.style.display = "block";
  };

  return (
    <div className="essay-feedback-parent-warps">
      <div
        id="myModalOne"
        style={{ display: feedBackQuestionModal ? "block" : "none" }}
        className="modal"
      >
        <div className="modal-content">
          <span onClick={handleCloseModal} className="close">
            &times;
          </span>
          <img
            id="modalImage"
            src={feedBackQuestionImage}
            alt="queimage"
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="feedback-header-section">
        <div className="header-first-section">
          <img
            src="https://cdn4.iconfinder.com/data/icons/wirecons-free-vector-icons/32/back-alt-512.png"
            alt="back icon"
            width="32px"
            height="32px"
            onClick={handleNavigate}
            style={{ cursor: "pointer" }}
          />
          <p className="commonHeaderBoldStyles">Dashboard </p>
          <strong>
            <KeyboardArrowRightIcon />
          </strong>
          <p className="headerMainTitle">Essay Feedback</p>
        </div>
        <div className="navSection">
          <a style={{ color: "#008dff" }} href="/essays">
            Go to Essays
          </a>
          <div style={{ position: "relative", margin: "0px 14px" }}>
            <Button type="button" className="view-download-btn">
              View download page
            </Button>
          </div>
        </div>
      </div>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        className="question-tabs"
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="lab API tabs example"
        >
          {feedBackState?.question_responses?.map((data, index) => (
            <Tab
              key={index}
              label={"Question " + (index + 1)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {feedBackState?.question_responses?.map((data, questionIndex) => (
        <TabPanel value={tabValue} index={questionIndex}>
          <div className="question-parent-div-warp">
            <strong className="question-lables">Question</strong>
            <div className="question-values">{data?.question}</div>
          </div>
          <div className="question-parent-div-warp">
            <strong className="question-lables">Overall Feedback :</strong>
            <div>
              <p
                className="question-div-value-label"
                contentEditable={true}
                onBlur={(e) =>
                  updateOverallFeedback(questionIndex, e.target.innerText)
                }
                //   onInput={this.handleChange}
                ref={inputRef}
                dangerouslySetInnerHTML={{
                  __html: `<ul>${data.overall_feedback
                    .map((item) => `<li>${item}</li>`)
                    .join("")}</ul>`,
                }}
              />
              {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}
              {/* <ul className='question-values'>
                                {data?.overall_feedback?.map((overallFeedbackData, index) => (
                                    <li key={index} contentEditable={true}
                                        onBlur={(e) => updateOverallFeedback(questionIndex, index, e.target.innerText)}
                                    >{overallFeedbackData}</li>
                                ))}
                            </ul> */}
            </div>
          </div>

          {data?.pages?.map((page, pageIndex) => (
            <div
              key={pageIndex}
              style={{ display: pageIndex === quePageTab ? "block" : "none" }}
              className={`tab-page, tab-${questionIndex}, page`}
            >
              <div className="page-content-main-wrapper">
                <div className="page-content-image-wrapper">
                  <img
                    src={page?.image_url}
                    alt="pages"
                    style={{ width: 500 }}
                    onClick={() => openModal(page?.image_url)}
                  />
                  <div
                    id={`overlay-${questionIndex}-${pageIndex}`}
                    className="image-overlay"
                  ></div>
                </div>
                <div className="page-content-feedback-section-wrapper">
                  {page.feedback.map((pageData, pageDataIndex) => (
                    <div
                      key={pageDataIndex}
                      data-index={questionIndex}
                      data-pageindex={pageIndex}
                      data-feedbackbox={JSON.stringify(pageData?.box)}
                      className="feedback-card"
                    >
                      <div className="feedbackCardInnerWrapper">
                        <div
                          className={`card-banner ${getPriorityBannerClass(
                            pageData.priority_score
                          )}`}
                        >
                          {" "}
                        </div>
                      </div>
                      <p
                        contentEditable={true}
                        onBlur={(e) =>
                          updateFeedback(
                            questionIndex,
                            pageIndex,
                            pageDataIndex,
                            e.target.innerText
                          )
                        }
                        className="feedbackprioritycontent"
                      >
                        {pageData.text}
                      </p>
                    </div>
                  ))}
                  {/* {cardErrorMessages[pageDataIndex] && <div className="error-message">{cardErrorMessages[pageDataIndex]}</div>} */}
                  {/* {cardErrorMessages && <div className="error-message">{cardErrorMessages}</div>} */}
                </div>
              </div>
            </div>
          ))}
          <div style={{ display: "flex" }}>
            <div style={{ width: "40%" }}>
              <Checkbox
                id="highlightToggle"
                checked={enableHighlightingState}
                style={{ display: "none" }}
              />
              <FormGroup>
                {/* <FormControlLabel control={<Checkbox defaultChecked />} label="" /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      id="highlightToggle"
                      checked={enableHighlightingState}
                      onChange={(e) =>
                        setEnableHighlightingState(e.target.checked)
                      }
                    />
                  }
                  label="Enable Highlighting"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={topPriorityState}
                      onChange={(e) => setTopPriorityState(e.target.checked)}
                    />
                  }
                  label="Highlight Top Priority"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mediumPriorityState}
                      onChange={(e) => setMediumPriorityState(e.target.checked)}
                    />
                  }
                  label="Highlight Medium Priority"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lowPriorityState}
                      onChange={(e) => setLowPriorityState(e.target.checked)}
                    />
                  }
                  label="Highlight Low Priority"
                />
              </FormGroup>
            </div>
            <div className="pagination-container">
              {data?.pages?.map((page, pageIndex) => (
                <Button
                  key={pageIndex}
                  className="paginationBtn"
                  onClick={() => showPage(questionIndex, pageIndex)}
                >
                  {pageIndex + 1}
                </Button>
              ))}
            </div>
          </div>
        </TabPanel>
      ))}
      <div
        className="btn-main-wrapper"
        style={{ display: "flex", justifyContent: "center", padding: "10px" }}
      >
        <Button
          variant="contained"
          className="saveAllChanges-btn"
          onClick={() => saveFeedbackData()}
        >
          Save all changes
        </Button>
      </div>
    </div>
  );
}

export default GradedEssay;
