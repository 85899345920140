import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { TablePagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../api/client";
import { toast } from "react-toastify";
import moment from "moment/moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Mystudent() {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [studentSearchQuery, setStudentSearchQuery] = useState("");

  const roleType = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    const currentDate = moment(new Date()).toISOString();
    if (currentDate > roleType?.expiry) {
      localStorage.removeItem("userDetails");
      toast.error("session expired");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/getstudents/?teacherid=${roleType?.loginData?.id}`)
      .then((res) => {
        const respo = res?.status === 200;
        if (respo) {
          setStudentData(res?.data?.students);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("error in axios", err);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStudentViewProfile = (e, row) => {
    navigate(`/students/${row?.id}`, { state: { data: row } });
  };

  const handleAddStudent = () => {
    navigate("/add-student");
  };

  const handleSearch = (event) => {
    setStudentSearchQuery(event.target.value.trim());
    setPage(0);
  };

  const studentSearchData =
    studentSearchQuery !== ""
      ? studentData?.filter(
          (row) =>
            row?.first_name
              .toLowerCase()
              .includes(studentSearchQuery.toLowerCase()) ||
            row?.last_name
              .toLowerCase()
              .includes(studentSearchQuery.toLowerCase()) ||
            String(row?.id)
              .toLowerCase()
              .includes(studentSearchQuery.toLowerCase()) ||
            row?.roll_number
              .toLowerCase()
              .includes(studentSearchQuery.toLowerCase())
        )
      : studentData;

  return (
    <div style={{ padding: "40px 20px" }}>
      <Grid container spacing={2}>
        <Grid item sx={12} md={6} lg={6}>
          <div>
            <h5 className="page-name">My Students</h5>
          </div>
        </Grid>
        <Grid item sx={12} md={4} lg={4}>
          <div className="studnet-search-div">
            <TextField
              className="search-field"
              name="regNumber"
              placeholder={"Search"}
              type={"text"}
              onChange={(e) => handleSearch(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ cursor: "pointer", marginLeft: "0px" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
        <Grid item sx={12} md={2} lg={2}>
          <div>
            <Button
              variant="contained"
              onClick={handleAddStudent}
              className="add-new-student-btn"
            >
              Add New Student
            </Button>
          </div>
        </Grid>
      </Grid>
      {loading ? (
        <div className="loder-div-styles">
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <TableContainer component={Paper} style={{ marginTop: "25px" }}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    className="myStudent-TH-cell1"
                  >
                    S. NO.
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className="myStudent-TH-cell2"
                  >
                    STUDENT ID
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className="myStudent-TH-cell5"
                  >
                    ROLL NUMBER
                  </StyledTableCell>
                  <StyledTableCell className="myStudent-TH-cell3">
                    FIRST NAME
                  </StyledTableCell>
                  <StyledTableCell className="myStudent-TH-cell3">
                    LAST NAME
                  </StyledTableCell>
                  <StyledTableCell className="myStudent-TH-cell3">
                    ESSAYS GRADED
                  </StyledTableCell>
                  {roleType?.loginData?.id === 86 && (
                    <>
                      <StyledTableCell className="myStudent-TH-cell3">
                        USERNAME
                      </StyledTableCell>
                      <StyledTableCell className="myStudent-TH-cell3">
                        PASSWORD
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell className="myStudent-TH-cell4">
                    ACTION
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {studentSearchData && studentSearchData?.length > 0 ? (
                  studentSearchData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <StyledTableRow key={page * rowsPerPage + index}>
                        <StyledTableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.roll_number}
                        </StyledTableCell>
                        <StyledTableCell>{row?.first_name}</StyledTableCell>
                        <StyledTableCell>{row?.last_name}</StyledTableCell>
                        <StyledTableCell>{row?.essay_count}</StyledTableCell>
                        {roleType?.loginData?.id === 86 && (
                          <>
                            <StyledTableCell>{row?.username}</StyledTableCell>
                            <StyledTableCell>{row?.password}</StyledTableCell>
                          </>
                        )}
                        <StyledTableCell
                          onClick={(e) => handleStudentViewProfile(e, row)}
                          className="view-profile-tag"
                        >
                          view profile
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={roleType?.loginData?.id === 86 ? 9 : 7}
                      align="center"
                    >
                      No Data Found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {studentSearchData?.length > 10 && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={studentSearchData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Mystudent;
