/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
// import { setGsContent } from "../defaultInstructions";

const DetectedPage = () => {
  const [deletedImages, setDeletedImagesState] = useState([]);
  const [, setDraggedElementParent] = useState("");
  const hasDisplayed = useRef(false);
  const [totalQ, setTotalQ] = useState("");
  const [idx, setIdx] = useState(0);

  const handleCheckboxChange = (idx) => {
    toast.success("Questions confirmed successfully!");

    let questionsArray = [];
    let pagesPerQuestionArray = [];
    let emptyStringsArray = [];
    let maxMarksArray = [];
    let maxWordsArray = [];
    let modelSolutionArray = [];
    let imageUrlsArray = [];
    let flagsArray = [];
    let tq = 0;
    let nq = 0;

    // Collect max marks
    document.querySelectorAll(".maxMarksValueElem").forEach((input) => {
      maxMarksArray.push(Number(input.innerText));
    });

    // Collect max words
    document.querySelectorAll(".maxWordsValueElem").forEach((input) => {
      maxWordsArray.push(Number(input.innerText));
    });

    // Collect questions and associated data
    const questionInputs = document.querySelectorAll(".questionInput");

    questionInputs.forEach((input, index) => {
      // Add question input value to questionsArray
      questionsArray.push(input.value);

      // Select the image column corresponding to the current index
      const columnImageColumn = document.querySelectorAll(
        ".column.image-column"
      )[index];
      const children = columnImageColumn.children;

      let countFlex = 0;
      const imageUrls = []; // Array to hold image URLs for this question

      for (let i = 0; i < children.length; i++) {
        const childStyle = getComputedStyle(children[i]);

        // Check if the element is displayed and flex
        if (childStyle.display !== "none" && childStyle.display === "flex") {
          countFlex++;
          const imgElements = children[i].querySelectorAll("img");

          // Iterate through all image elements and add their src to the imageUrls array
          imgElements.forEach((imgElement) => {
            if (imgElement) {
              imageUrls.push(imgElement.src);
            }
          });
        }
      }

      // Add the count of flex items and image URLs to their respective arrays
      pagesPerQuestionArray.push(countFlex);
      imageUrlsArray.push(imageUrls);

      // Collect user input for empty strings array
      const userInput = input.parentNode.parentNode.querySelector(
        ".textbox-column textarea"
      ).value;
      const userInputArrays = userInput?.split(/\n\s*\n/);
      userInputArrays?.forEach((subArray) => {
        const lines = subArray?.split("\n");
        const updatedLines = lines?.map((line) =>
          line?.replace(/^\d+\.\s*/, "")
        );
        emptyStringsArray?.push(updatedLines?.join("\n"));
      });

      // Collect model solution input
      const modelSolutionUserInput = input.parentNode.parentNode.querySelector(
        ".ModelSolution-textbox-column textarea"
      ).value;
      modelSolutionArray.push(modelSolutionUserInput);

      // Check if the current row has the class 'new-question'
      // console.log(input.parentNode.parentNode);
      const rowDataRow = input.parentNode.parentNode;
      tq++;
      console.log(rowDataRow, tq);
      if (rowDataRow?.classList.contains("new-question")) {
        flagsArray.push(1);
        nq++;
      } else {
        flagsArray.push(0);
      }
    });

    // Set deleted images state
    setDeletedImagesState(deletedImages);

    setTotalQ(tq);

    // Create the new table state
    const newTableState = {
      questionsArray,
      pagesPerQuestionArray,
      emptyStringsArray,
      maxMarksArray,
      maxWordsArray,
      modelSolutionArray,
      imageUrlsArray,
      deletedImages,
      flagsArray,
      tq,
      nq,
    };

    // Save tableState to local storage with key including idx
    localStorage.setItem(`tableState_${idx}`, JSON.stringify(newTableState));

    // Post message to other tab
    window.opener.postMessage(
      {
        type: "CHECKBOX_CHANGE",
        idx,
        newData: {
          questionsArray,
          pagesPerQuestionArray,
          emptyStringsArray,
          maxMarksArray,
          maxWordsArray,
          modelSolutionArray,
          imageUrlsArray,
          deletedImages,
          flagsArray,
          tq,
          nq,
        },
      },
      "*"
    );
  };

  function displayQuestionsAndImagesWithoutTemplate(data, exam) {
    const container = document.getElementById("responseContainer");
    const headingsRow = document.createElement("div");
    headingsRow.className = "row headings-row";
    headingsRow.innerHTML = `
            <div class="column question-column" style="display: flex; justify-content: center;"><strong>Question</strong></div>
            <div class="column image-column-header" style="display: flex; justify-content: center;"><strong>Pages</strong></div>
            <div class="column ModelSolution-textbox-column-header" style="display: flex; justify-content: center;"><strong>Model Solution</strong></div>
            <div class="column textbox-column-header" style="display: flex; justify-content: center;"><strong>Special Instructions</strong></div>
        `;
    container.innerHTML = "";
    container.appendChild(headingsRow);

    console.log("My", data);

    data?.question_responses?.forEach((question, index) => {
      const rowDiv = document.createElement("div");
      rowDiv.className = "row data-row";
      rowDiv.setAttribute("data-index", index);

      // Question column
      const questionDiv = document.createElement("div");
      questionDiv.className = "column question-column";
      const questionInput = document.createElement("textarea");
      const questionMetaDataDiv = document.createElement("div");
      questionMetaDataDiv.className = "questionMetaDataDiv";
      const wrapElem1 = document.createElement("div");
      wrapElem1.className = "sampleSpanWrapper";
      const maxMarksElem = document.createElement("span");
      maxMarksElem.innerText = "Maximum marks per question : ";
      const maxMarksValueElem = document.createElement("span");
      maxMarksValueElem.className = "maxMarksValueElem";
      maxMarksValueElem.innerText = question.question_points
        ? question.question_points
        : 0;
      maxMarksValueElem.contentEditable = true;
      maxMarksValueElem.addEventListener("click", function () {
        maxMarksValueElem.classList.toggle("highlight");
      });
      wrapElem1.appendChild(maxMarksElem);
      wrapElem1.appendChild(maxMarksValueElem);
      questionMetaDataDiv.appendChild(wrapElem1);
      const wrapElem2 = document.createElement("div");
      wrapElem2.className = "sampleSpanWrapper";
      const maxWordsElem = document.createElement("span");
      maxWordsElem.innerText = "Maximum words per question : ";
      const maxWordsValueElem = document.createElement("span");
      maxWordsValueElem.className = "maxWordsValueElem";
      maxWordsValueElem.innerText = question.words_parsed
        ? question.words_parsed
        : 0;
      maxWordsValueElem.contentEditable = true;
      maxWordsValueElem.addEventListener("click", function () {
        maxWordsValueElem.classList.toggle("highlight");
      });
      wrapElem2.appendChild(maxWordsElem);
      wrapElem2.appendChild(maxWordsValueElem);
      questionMetaDataDiv.appendChild(wrapElem2);
      questionInput.style.resize = "none";
      questionInput.style.minHeight = "7em";
      questionInput.value = question.question;
      questionInput.className =
        "questionInput form-control customAlign customtextareaScroll";
      questionInput.numPages = question.pages?.length;
      questionInput.addEventListener("input", collectQuestions);
      questionDiv.appendChild(questionInput);
      questionDiv.appendChild(questionMetaDataDiv);
      questionInput.ondragover = allowDrop;
      questionInput.ondrop = function (event) {
        drop(event, index);
      };

      // Image column
      const imageDiv = document.createElement("div");
      imageDiv.className = "column image-column";
      imageDiv.ondragover = allowDrop;
      imageDiv.ondrop = function (event) {
        drop(event, index);
      };
      question?.pages?.forEach((page) => {
        const wrapperDiv = document.createElement("div");
        wrapperDiv.className = "question-wrapper-div";
        const img = document.createElement("img");
        img.src = page.image_url;
        img.className = "thumbnail";
        img.style.width = "80px";
        img.style.height = "140px";
        img.draggable = true;
        img.ondragstart = drag;
        img.onclick = function () {
          openModal(this.src);
        };
        const closeIcon = document.createElement("span");
        closeIcon.className = "close-icon";
        closeIcon.innerHTML = "X";
        closeIcon.onclick = function () {
          closeImageContainer(wrapperDiv);
        };
        wrapperDiv.appendChild(img);
        wrapperDiv.appendChild(closeIcon);
        imageDiv.appendChild(wrapperDiv);
        wrapperDiv.addEventListener("mouseover", function () {
          showCloseIcon(this);
        });

        wrapperDiv.addEventListener("mouseout", function () {
          hideCloseIcon(this);
        });
      });

      // Model Solution
      const modelSolutionDiv = document.createElement("div");
      modelSolutionDiv.className =
        "column ModelSolution-textbox-column modelSolutionCustomColumnBorder";
      const modelSolutioninput = document.createElement("textarea");
      modelSolutioninput.style.resize = "none";
      modelSolutioninput.style.minHeight = "7em";
      modelSolutioninput.value =
        question.specialInstructions === undefined
          ? ""
          : question.specialInstructions;
      modelSolutioninput.className =
        "form-control customAlign textareacustomclass";
      modelSolutioninput.ondragover = allowDrop;
      modelSolutioninput.ondrop = function (event) {
        drop(event, index);
      };
      modelSolutionDiv.appendChild(modelSolutioninput);
      let modalSolutionActionDiv = document.createElement("div");
      modalSolutionActionDiv.className = "instruction-action-area";
      let modalSolutionAddrowaction = document.createElement("div");
      modalSolutionAddrowaction.className = "addrowaction row-options";
      let modalSolutionInstructionsCount = document.createElement("p");
      modalSolutionInstructionsCount.setAttribute("id", "instructionsCount");
      modalSolutionInstructionsCount.className = "instCountClass";
      modalSolutionAddrowaction.appendChild(modalSolutionInstructionsCount);
      modelSolutionDiv.appendChild(modalSolutionAddrowaction);

      // Textbox column
      const textboxDiv = document.createElement("div");
      textboxDiv.className = "column textbox-column customColumnBorder";
      const input = document.createElement("textarea");
      input.style.resize = "none";
      input.style.minHeight = "7em";
      // input.value = setGsContent(exam).toString();

      input.className = "form-control customAlign textareacustomclass";
      input.ondragover = allowDrop;
      input.ondrop = function (event) {
        drop(event, index);
      };
      input.addEventListener("focusout", function () {
        const lines = this.value.split("\n");
        let formattedText = "";

        lines?.forEach((line, index) => {
          if (line.trim() !== "") {
            if (!/^\d+\.\s/.test(line)) {
              formattedText += index + 1 + ". " + line + "\n";
            } else {
              formattedText += line + "\n";
            }
          }
        });
        this.value = formattedText.trim();
      });
      textboxDiv.appendChild(input);
      let actionDiv = document.createElement("div");
      actionDiv.className = "instruction-action-area";
      let addrowaction = document.createElement("div");
      addrowaction.className = "addrowaction row-options";
      let instructionsCount = document.createElement("p");
      instructionsCount.setAttribute("id", "instructionsCount");
      instructionsCount.className = "instCountClass";
      let addrowbtn = document.createElement("button");
      addrowbtn.innerText = "+";
      addrowbtn.className = "btn btn-secondary custombtnspacing add-row";
      let delrowbtn = document.createElement("button");
      delrowbtn.innerText = "-";
      delrowbtn.className = "btn btn-secondary custombtnspacing delete-row";
      addrowaction.appendChild(instructionsCount);
      addrowaction.appendChild(addrowbtn);
      addrowaction.appendChild(delrowbtn);
      textboxDiv.appendChild(addrowaction);

      rowDiv.appendChild(questionDiv);
      rowDiv.appendChild(imageDiv);
      rowDiv.appendChild(modelSolutionDiv);
      rowDiv.appendChild(textboxDiv);
      container.appendChild(rowDiv);
      container.style.display = "block";
    });
    assignIndexValueForImages();
    initiatePopUpAction();
  }

  function displayQuestionsAndImagesWithoutTemplateLS(data, exam, idx) {
    const container = document.getElementById("responseContainer");
    const headingsRow = document.createElement("div");
    headingsRow.className = "row headings-row";
    headingsRow.innerHTML = `
            <div class="column question-column" style="display: flex; justify-content: center;"><strong>Question</strong></div>
            <div class="column image-column-header" style="display: flex; justify-content: center;"><strong>Pages</strong></div>
            <div class="column ModelSolution-textbox-column-header" style="display: flex; justify-content: center;"><strong>Model Solution</strong></div>
            <div class="column textbox-column-header" style="display: flex; justify-content: center;"><strong>Special Instructions</strong></div>
        `;
    container.innerHTML = "";
    container.appendChild(headingsRow);

    console.log("My", data);

    const totalQuestionsSpan = document.querySelector(
      ".ques-count-warp h3:nth-child(1) span"
    );
    const totalNewQuestionsSpan = document.querySelector(
      ".ques-count-warp h3:nth-child(2) span"
    );
    totalQuestionsSpan.textContent = data.tq;
    totalNewQuestionsSpan.textContent = data.nq;

    data?.questionsArray?.forEach((question, index) => {
      const rowDiv = document.createElement("div");
      if (data?.flagsArray[index] === 1) {
        rowDiv.className = "row data-row new-rowadded-bg";
      } else {
        rowDiv.className = "row data-row";
      }

      rowDiv.setAttribute("data-index", index);

      // Question column
      const questionDiv = document.createElement("div");
      questionDiv.className = "column question-column";
      const questionInput = document.createElement("textarea");
      const questionMetaDataDiv = document.createElement("div");
      questionMetaDataDiv.className = "questionMetaDataDiv";
      const wrapElem1 = document.createElement("div");
      wrapElem1.className = "sampleSpanWrapper";
      const maxMarksElem = document.createElement("span");
      maxMarksElem.innerText = "Maximum marks per question : ";
      const maxMarksValueElem = document.createElement("span");
      maxMarksValueElem.className = "maxMarksValueElem";
      maxMarksValueElem.innerText = data.maxMarksArray[index]
        ? data.maxMarksArray[index]
        : 0;
      maxMarksValueElem.contentEditable = true;
      maxMarksValueElem.addEventListener("click", function () {
        maxMarksValueElem.classList.toggle("highlight");
      });
      wrapElem1.appendChild(maxMarksElem);
      wrapElem1.appendChild(maxMarksValueElem);
      questionMetaDataDiv.appendChild(wrapElem1);
      const wrapElem2 = document.createElement("div");
      wrapElem2.className = "sampleSpanWrapper";
      const maxWordsElem = document.createElement("span");
      maxWordsElem.innerText = "Maximum words per question : ";
      const maxWordsValueElem = document.createElement("span");
      maxWordsValueElem.className = "maxWordsValueElem";
      maxWordsValueElem.innerText = data.maxWordsArray[index]
        ? data.maxWordsArray[index]
        : 0;
      maxWordsValueElem.contentEditable = true;
      maxWordsValueElem.addEventListener("click", function () {
        maxWordsValueElem.classList.toggle("highlight");
      });
      wrapElem2.appendChild(maxWordsElem);
      wrapElem2.appendChild(maxWordsValueElem);
      questionMetaDataDiv.appendChild(wrapElem2);
      questionInput.style.resize = "none";
      questionInput.style.minHeight = "7em";
      questionInput.value = question;
      questionInput.className =
        "questionInput form-control customAlign customtextareaScroll";
      questionInput.numPages = data.pagesPerQuestionArray[index];
      questionInput.addEventListener("input", collectQuestions);
      questionDiv.appendChild(questionInput);
      questionDiv.appendChild(questionMetaDataDiv);
      questionInput.ondragover = allowDrop;
      questionInput.ondrop = function (event) {
        drop(event, index);
      };

      // Image column
      const imageDiv = document.createElement("div");
      imageDiv.className = "column image-column";
      imageDiv.ondragover = allowDrop;
      imageDiv.ondrop = function (event) {
        drop(event, index);
      };

      // Iterate through each URL in the page array
      data?.imageUrlsArray[index].forEach((imageUrl) => {
        const wrapperDiv = document.createElement("div");
        wrapperDiv.className = "question-wrapper-div";
        // Create an image element for each URL
        const img = document.createElement("img");
        img.src = imageUrl;
        img.className = "thumbnail";
        img.style.width = "80px";
        img.style.height = "140px";
        img.draggable = true;
        img.ondragstart = drag;
        img.onclick = function () {
          openModal(this.src);
        };

        // Create a close icon element
        const closeIcon = document.createElement("span");
        closeIcon.className = "close-icon";
        closeIcon.innerHTML = "X";
        closeIcon.onclick = function () {
          closeImageContainer(wrapperDiv);
        };

        // Append the image and close icon to the wrapper div
        wrapperDiv.appendChild(img);
        wrapperDiv.appendChild(closeIcon);
        imageDiv.appendChild(wrapperDiv);

        // Add mouseover and mouseout event listeners to the wrapper div
        wrapperDiv.addEventListener("mouseover", function () {
          showCloseIcon(this);
        });

        wrapperDiv.addEventListener("mouseout", function () {
          hideCloseIcon(this);
        });
      });

      // Append the wrapper div to the main image container

      // });

      // Model Solution
      const modelSolutionDiv = document.createElement("div");
      modelSolutionDiv.className =
        "column ModelSolution-textbox-column modelSolutionCustomColumnBorder";
      const modelSolutioninput = document.createElement("textarea");
      modelSolutioninput.value = data.modelSolutionArray[index];
      modelSolutioninput.style.resize = "none";
      modelSolutioninput.style.minHeight = "7em";
      modelSolutioninput.className =
        "form-control customAlign textareacustomclass";
      modelSolutioninput.ondragover = allowDrop;
      modelSolutioninput.ondrop = function (event) {
        drop(event, index);
      };
      modelSolutionDiv.appendChild(modelSolutioninput);
      let modalSolutionActionDiv = document.createElement("div");
      modalSolutionActionDiv.className = "instruction-action-area";
      let modalSolutionAddrowaction = document.createElement("div");
      modalSolutionAddrowaction.className = "addrowaction row-options";
      let modalSolutionInstructionsCount = document.createElement("p");
      modalSolutionInstructionsCount.setAttribute("id", "instructionsCount");
      modalSolutionInstructionsCount.className = "instCountClass";
      modalSolutionAddrowaction.appendChild(modalSolutionInstructionsCount);
      modelSolutionDiv.appendChild(modalSolutionAddrowaction);

      // Textbox column
      const textboxDiv = document.createElement("div");
      textboxDiv.className = "column textbox-column customColumnBorder";
      const input = document.createElement("textarea");
      input.style.resize = "none";
      input.style.minHeight = "7em";
      // input.value = setGsContent(exam).toString();

      input.className = "form-control customAlign textareacustomclass";
      input.ondragover = allowDrop;
      input.ondrop = function (event) {
        drop(event, index);
      };
      input.addEventListener("focusout", function () {
        const lines = this.value.split("\n");
        let formattedText = "";

        lines?.forEach((line, index) => {
          if (line.trim() !== "") {
            if (!/^\d+\.\s/.test(line)) {
              formattedText += index + 1 + ". " + line + "\n";
            } else {
              formattedText += line + "\n";
            }
          }
        });
        this.value = formattedText.trim();
      });
      textboxDiv.appendChild(input);
      let actionDiv = document.createElement("div");
      actionDiv.className = "instruction-action-area";
      let addrowaction = document.createElement("div");
      addrowaction.className = "addrowaction row-options";
      let instructionsCount = document.createElement("p");
      instructionsCount.setAttribute("id", "instructionsCount");
      instructionsCount.className = "instCountClass";
      let addrowbtn = document.createElement("button");
      addrowbtn.innerText = "+";
      addrowbtn.className = "btn btn-secondary custombtnspacing add-row";
      let delrowbtn = document.createElement("button");
      delrowbtn.innerText = "-";
      delrowbtn.className = "btn btn-secondary custombtnspacing delete-row";
      addrowaction.appendChild(instructionsCount);
      addrowaction.appendChild(addrowbtn);
      addrowaction.appendChild(delrowbtn);
      textboxDiv.appendChild(addrowaction);

      rowDiv.appendChild(questionDiv);
      rowDiv.appendChild(imageDiv);
      rowDiv.appendChild(modelSolutionDiv);
      rowDiv.appendChild(textboxDiv);
      container.appendChild(rowDiv);
      container.style.display = "block";
    });
    assignIndexValueForImages();
    initiatePopUpAction();
  }

  window.addEventListener("message", function (event) {
    if (event.origin !== window.location.origin) {
      // Ignore messages from unexpected origins
      return;
    }

    // Only process messages with the correct type
    if (event.data && event.data.type === "myAppMessage") {
      console.log("Application message received:", event.data);
      setIdx(event?.data?.index);
      setTotalQ(event?.data?.state?.data_json?.question_responses.length);
      const savedTableState = localStorage.getItem(
        `tableState_${event?.data?.index}`
      );
      if (savedTableState) {
        setTotalQ(savedTableState?.tq);
        displayQuestionsAndImagesWithoutTemplateLS(
          JSON.parse(savedTableState),
          event?.data?.exam,
          event?.data?.index
        );
      } else {
        displayQuestionsAndImagesWithoutTemplate(
          event?.data?.state?.data_json,
          event?.data?.exam
        );
      }
      // Process the received data
    } else {
      console.log("Ignored message:", event.data);
    }
  });

  function assignIndexValueForImages() {
    let elems = document.getElementsByClassName("thumbnail");
    for (let iter = 0; iter < elems.length; iter++) {
      elems[iter].setAttribute("imgIndex", iter);
      elems[iter].parentElement.setAttribute("imgIndex", iter);
    }
  }

  function collectQuestions() {
    var questionTexts = [];
    var questionInputs = document.querySelectorAll(".question-column input");
    questionInputs?.forEach(function (questionInput) {
      questionTexts.push(questionInput.value.trim());
    });
    return questionTexts;
  }

  function openModal(src) {
    var modal = document.getElementById("myModal");
    var modalImg = document.getElementById("modalImage");
    var span = document.getElementsByClassName("close")[0];
    modal.style.display = "block";
    modalImg.src = src;
    span.onclick = function () {
      modal.style.display = "none";
    };
    document.addEventListener("keydown", function (event) {
      if (
        event.key === "Escape" ||
        event.key === "Esc" ||
        event.keyCode === 27
      ) {
        modal.style.display = "none";
      }
    });
  }

  function closeImageContainer(container) {
    container.style.display = "none";
    container.parentNode.removeChild(container);
    const imgIndex = container.getAttribute("imgindex");
    setDeletedImagesState((prevDeletedImages) => {
      const updatedDeletedImages = [...prevDeletedImages, parseInt(imgIndex)];
      updatedDeletedImages.sort((a, b) => a - b);
      return updatedDeletedImages;
    });
  }

  function showCloseIcon(container) {
    const closeIcon = container.querySelector(".close-icon");
    closeIcon.style.visibility = "visible";
  }

  function hideCloseIcon(container) {
    const closeIcon = container.querySelector(".close-icon");
    closeIcon.style.visibility = "hidden";
  }

  function allowDrop(event) {
    event.preventDefault();
  }

  function drag(event) {
    var crt = this.cloneNode(true);
    var previewContainer = document.createElement("div");
    previewContainer.appendChild(crt);
    document.body.appendChild(previewContainer);
    event.dataTransfer.setData("text", event.target.getAttribute("imgIndex"));
    setDraggedElementParent(event.target.parentElement.parentElement);
    event.dataTransfer.setDragImage(crt, 0, 0);
    event.target.addEventListener("dragend", function () {
      previewContainer.remove();
    });
  }

  function drop(event, rowIndex) {
    event.preventDefault();
    if (event.target.tagName.toLowerCase() === "textarea") {
      return;
    }
    const draggedIndex = event.dataTransfer.getData("text");
    const draggedImage = document.querySelector(`[imgIndex='${draggedIndex}']`);
    const targetRow = event.target.closest(".row.data-row");
    const targetImageColumn = targetRow.querySelector(".image-column");
    const children = Array.from(targetImageColumn.children);
    const targetIndex = children.findIndex(
      (img) => +img.getAttribute("imgIndex") > +draggedIndex
    );

    if (children.length === 0) {
      let tempDragElemParent = draggedImage.closest(".column.image-column");
      let tempDraggedImage = draggedImage;
      if (
        parseInt(
          draggedImage.closest(".row.data-row").getAttribute("data-index")
        ) < parseInt(targetRow.getAttribute("data-index"))
      ) {
        if (
          tempDragElemParent === "" ||
          tempDragElemParent.lastChild.isEqualNode(tempDraggedImage)
        ) {
          targetImageColumn.appendChild(draggedImage);
        }
      } else {
        if (
          tempDragElemParent === "" ||
          tempDragElemParent.firstChild.isEqualNode(tempDraggedImage)
        ) {
          targetImageColumn.appendChild(draggedImage);
        }
      }
    } else {
      if (isValidRearrange(targetIndex, draggedIndex, children)) {
        if (targetIndex === -1) {
          targetImageColumn.appendChild(draggedImage);
        } else {
          targetImageColumn.insertBefore(draggedImage, children[targetIndex]);
        }
      }
    }
  }

  function isValidRearrange(targetIndex, draggedIndex, children) {
    if (targetIndex === -1) {
      const lastIndex = children.length - 1;
      const gap =
        lastIndex >= 0
          ? +draggedIndex - +children[lastIndex].getAttribute("imgIndex")
          : 0;
      return gap === 1;
    } else {
      const gap =
        +children[targetIndex].getAttribute("imgIndex") - +draggedIndex;
      return (
        gap === 1 &&
        !children.some((img) => img.getAttribute("imgIndex") === draggedIndex)
      );
    }
  }

  function checkEmptyDivExists(elem) {
    return (
      elem.find(".column.image-column").find("img").length === 0 &&
      elem.find(".question-column textarea").val().trim() === "" &&
      elem.find(".textbox-column textarea").val().trim() === "" &&
      elem.find(".ModelSolution-textbox-column textarea").val().trim() === ""
    );
  }

  function reassignIndexValues() {
    const elements = document.querySelectorAll(".row.data-row");
    setTotalQ(elements?.length);
    elements?.forEach((element, index) => {
      element.dataset.index = index;
    });
  }

  function initiatePopUpAction() {
    $(document).off("click", ".add-row");
    $(document).off("click", ".delete-row");
    $(document).on("click", ".add-row", function () {
      var newRow = $(this).closest(".row").clone();
      var addClickedRow = $(this).closest(".row");
      let newIndex = parseInt(addClickedRow[0].dataset.index) + 1;
      newRow.find("input[type=text]").val("");
      newRow.children()[1].innerHTML = "";
      // Add a class to the new row
      newRow.addClass("new-question");
      newRow.children().addClass("new-rowadded-bg");
      newRow.find("textarea")[0].value = "";
      newRow.find("textarea")[1].value = "";
      newRow.find("textarea")[2].value = "";
      newRow.find(".maxMarksValueElem")[0].innerText = 0;
      newRow.find(".maxWordsValueElem")[0].innerText = 0;
      newRow.attr("data-index", newIndex);
      newRow.find(".image-column").on("dragover", function (event) {
        event.preventDefault();
      });
      newRow.find(".image-column").on("drop", function (event) {
        var originalEvent = event.originalEvent;
        drop(originalEvent, newIndex);
      });

      newRow.find(".thumbnail").off("dragstart");
      newRow.find(".thumbnail").attr("draggable", true);
      newRow.find(".thumbnail").on("dragstart", drag);

      newRow.find("textarea")[2].addEventListener("focusout", function () {
        const lines = this.value.split("\n");
        let formattedText = "";

        lines?.forEach((line, index) => {
          if (line.trim() !== "") {
            if (!/^\d+\.\s/.test(line)) {
              formattedText += index + 1 + ". " + line + "\n";
            } else {
              formattedText += line + "\n";
            }
          }
        });
        this.value = formattedText.trim();
      });

      $(this).closest(".row").after(newRow);
      reassignIndexValues();
    });

    $(document).on("click", ".delete-row", function () {
      if ($(".row").length > 1) {
        let closestRow = $(this).closest(".row");
        let isEmptyDiv = checkEmptyDivExists(closestRow);
        if (isEmptyDiv) {
          $(this).closest(".row").remove();
          reassignIndexValues();
        } else {
          toast.error("Cannot delete row that contains existing values");
        }
      } else {
        toast.error("Cannot delete the only row.");
      }
    });
  }

  return (
    <>
      <div className="show-content-total-que-warp instructions-content">
        <div>
          <h5 className="edit-questions-h5">
            Edit detected questions and custom instructions as required
          </h5>
        </div>
        <div className="ques-count-warp">
          <h3 className="remove-margin-h" style={{ width: 200 }}>
            Total questions :&nbsp;&nbsp;
            <span>{$(".row.data-row")?.length}</span>
          </h3>
          <h3 className="remove-margin-h" style={{ width: 200 }}>
            Total New questions :&nbsp;&nbsp;
            <span>{$(".column.question-column.new-rowadded-bg")?.length}</span>
          </h3>
        </div>
      </div>
      <div id="responseContainer">
        <div className="row">
          <div className="column question-column">
            <strong>Question</strong>
          </div>
          <div className="column image-column">
            <strong>Pages</strong>
          </div>
          <div className="column textbox-column">
            <strong>Add Instructions</strong>
          </div>
        </div>
      </div>
      <div className="edit-questions-partent-div-warps">
        <div className="edit-questions-div-warps finalizewrapper">
          <Button
            onClick={() => handleCheckboxChange(idx)}
            id="finalizeButton"
            variant="contained"
            className="finalize-btn"
          >
            Confirm Questions
          </Button>
        </div>
      </div>
      <div id="myModal" className="modal-paper">
        <div className="modal-content">
          <span className="close">&times;</span>
          <img
            id="modalImage"
            src=""
            alt="modal-img"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

export default DetectedPage;
